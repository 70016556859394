import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { Global } from '@services/core/Global';
import { UserService } from '@services/core/user.service';
import { StorageService } from '@services/core/storage.service';

@Injectable({
    providedIn: 'root'
})

export class MicrosoftAuthService {
    private url;
    private url_back;
    public token: WritableSignal<string> = signal<string>(null)
    public refreshToken: WritableSignal<string> = signal<string>(null)
    public sharingRefreshToken = signal<string | undefined>(undefined)
    public sharingToken = signal<string | undefined>(undefined)

    storageService = inject(StorageService)

    constructor(
        private _http: HttpClient, 
    ) {
        this.url = Global.url_api_auth
        this.url_back = Global.url_auth
    }



    getTokenAPI(): Observable<any> {
        //convertir el objeto del usuario a un JSON string
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        //definir las cabeceras
        return this._http.get(this.url + "get-token", { headers: headers });
    }

    getAccessToken(data): Observable<any> {
        //convertir el objeto del usuario a un JSON string
        let params = JSON.stringify({ code: data })
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.post(this.url + "authorize-token", params, { headers: headers });
    }

    decryptToken(): Observable<any> {
        //convertir el objeto del usuario a un JSON string
        this.getToken()
        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this.token());
        //definir las cabeceras
        return this._http.get(this.url_back + "decrypt-token", { headers: headers });
    }

    refreshTokenAPI(): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        let refresh_token = this.refreshToken()
        return this._http.get(this.url + "refresh-token/" + refresh_token, { headers: headers });
    }

    logout(): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type', 'text/html; charset=utf-8').set('Authorization', 'Bearer ' + this.token());
        return this._http.get(this.url + "logout-session/" + this.token, { headers: headers });
    }

    getPhoto(): Observable<any> {
        //convertir el objeto del usuario a un JSON string
        let headers = new HttpHeaders().set('Content-Type', 'application/blob').set('Authorization', this.token());
        //definir las cabeceras
        return this._http.get(this.url + "get-photo", { headers: headers });
    }

   getToken() {
    if(this.storageService.getJsonValue("token") ){
        this.token.set(this.storageService.getJsonValue("token"))
    }
   }

    clearSesion() {
        this.storageService.clearStorage()
    }


}

