// context-menu.component.ts
import { Component, Input } from '@angular/core';

interface ContextMenuAction {
  label: string;
  icon:string;
  onClick: () => void;
}

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ["./context-menu.component.css"],
	standalone: true,
})
export class ContextMenuComponent {
  @Input() visible = false;
  @Input() actions: ContextMenuAction[] = [];
  x = 0;
  y = 0;

  show(x: number, y: number) {
    this.x = x;
    this.y = y;
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }
}
