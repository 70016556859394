<!-- BEGIN page-header privilegesUserAdmin() -->
@if(privilegesUserAdmin()){
<div>
    <h1 class="page-header"><i class="fas fa-users-cog text-dark ml-1 text-opacity-75"></i> Usuarios <small></small>
    </h1>
    <div class="panel panel-iatuch">
        <div class="panel-heading">
            <h4 class="panel-title"> <i [class.fa-user-edit]="titleUser==='Actualizar Usuario'"
                    [class.fa-user-plus]="titleUser==='Nuevo Usuario'"
                    [class.fa-user-cog]="titleUser==='Gestión de Usuarios'"
                    [class.fa-user]="titleUser==='Visualizar Usuario'"
                    class="fas fa-user-cog  fa-lg fa-fw text-opacity-50 me-1"></i> {{titleUser}}</h4>
        </div>

        @if(!btnUser){
        <!-- panel de  busqueda y tabla -->
        <div class="panel-body ">
            <div class="p-3">
                <section class="row">
                    <div class="col-12  col-md-4 col-xl-3  mt-2 ">
                        <input [(ngModel)]="txtSearch" type="email" (keyup)="onKeyUp()" (keydown)="onKeydown($event)"
                            placeholder="Ingresar Usuario" class="form-control form-control-sm ">
                    </div>

                    <div class="col-12  col-md-4 col-xl-2  mt-2">
                        <select [(ngModel)]="selectFilterRol" class="form-control form-select form-select-sm "
                            placement="top" ngbTooltip="Filtrar por Rol" (change)="getFindUser()">
                            <option value="" selected>Todos los Roles</option>
                            @for(items of role;track items._id; let idx = $index){
                                 @if(items.isActive){
                                <option [ngValue]="items._id">{{items.description }}</option>
                                 }
                            }
                        </select>

                    </div>
                    <div class="col-12  col-md-4 col-xl-2  mt-2">
                        <select [(ngModel)]="selectFilterCountry" class="form-control form-select form-select-sm"
                            placement="top" ngbTooltip="Filtrar por Campo" (change)="getFindUser()">
                            <option value="0" selected>Todos los campos</option>
                            @for(items of country;track items){
                            <option [ngValue]="items">{{items}}</option>
                            }
                        </select>

                    </div>
                    <div class="col-6  col-md-4 col-xl-2  mt-2">
                        <button (click)="getFindUser()" class="btn  btn-primary btn-xs "> <i
                                class="fa fa-search p-2"></i> Buscar&nbsp; &nbsp;</button>
                    </div>
                    <div class="col mt-2 d-flex justify-content-end justify-content-xs-left">
                        <button class="btn btn-success btn-xs" (click)="btnUser=true;showFormUser()">
                            <i class="fa fa-user-plus p-2 "></i> Nuevo Usuario&nbsp;&nbsp; </button>
                    </div>

            </section>


            @if(userData?.length === 0){
            <div class="row  justify-content-center row-cols-lg-auto align-items-center ">
                <h5 class="align-items-center opacity-50">No existe ningun registro</h5>
            </div>
            }
            @if(userData?.length>0){
            <div class="modal-body modal-scrollable table-container mt-4 ">
                <table class="table table-striped  table-sm ">
                    <thead class="fixed-header">
                        <tr>
                            <th>#</th>
                            <th (click)="orderCol('mail')"><span placement="right"
                                    ngbTooltip="Ordenar por Correo">Usuarios </span><i class="fas ml-2"
                                    [ngClass]="{'fa-sort-alpha-down-alt':!order&&orderColName==='mail','fa-sort-alpha-down':order&&orderColName==='mail'}"></i>
                            </th>
                            <th (click)="orderCol('role.description')"><span placement="right"
                                    ngbTooltip="Ordenar por Rol">Rol </span><i class="fas ml-2"
                                    [ngClass]="{'fa-sort-alpha-down-alt':!order&&orderColName==='role.description','fa-sort-alpha-down':order&&orderColName==='role.description'}"></i>
                            </th>

                            <th>Campo</th>
                            <th>Fecha Creación</th>
                            <th>Fecha de Expiración</th>
                            <th (click)="orderCol('isActive')"><span placement="right"
                                    ngbTooltip="Ordenar por Estado">Estado </span><i class="fas fa-sort-amount-down-alt"
                                    [ngClass]="{'fa-sort-amount-down':!order&&orderColName==='isActive','fa-sort-amount-down-alt':order&&orderColName==='isActive'}"></i>
                            </th>
                            <th class="text-center"><i class="fa fa-cogs "></i></th>
                        </tr>

                    </thead>

                    <tbody>
                        @for(user of userData;track user._id; let idx = $index)
                        {
                        <tr>
                            <td>{{idx+1}}</td>
                            <td>{{user.mail}}</td>
                            <td>{{user.role?.description}}</td>
                            <td>{{findUch(user.entity?.entities)?'UCh':user.entity?.selected?.campus}}
                            </td>
                            <td>{{user.createdAt|date:'dd/MM/yyyy'}}</td>
                            <td>{{user.expiration|date:'dd/MM/yyyy'}}</td>
                            <td> <span
                                    class="badge border   fs-12px pb-5px pt-5px px-2 rounded d-inline-flex align-items-center"
                                    [ngClass]="{ 'border-success text-success': user.isActive, 'border-danger text-danger': !user.isActive  }">
                                    <i
                                        class=" fas fa-circle fs-9px fa-fw me-5px "></i>{{user.isActive?'Activo':'Inactivo'}}</span>
                            </td>
                            <td class="text-center">
                                @if(privilegesUserAdmin(user)){
                                <button (click)="showFormUser(user)" class="btn btn-outline-primary btn-xs">
                                    <i class="fa fa-pencil-alt"></i>
                                </button>
                                &nbsp;}
                                @if(!privilegesUserAdmin(user)){
                                <button (click)="showFormUserView(user)" class="btn btn-outline-primary btn-xs">
                                    <i class="fas fa-eye"></i>
                                </button>
                                &nbsp;}
                                @if(user.isActive&&privilegesUserAdmin(user)){
                                <button class="btn btn-outline-danger btn-xs " (click)="updateUserActive(user,false) ">
                                    <i class="fa fa-user-slash"></i>
                                </button>
                                }
                                @if(!user.isActive&&privilegesUserAdmin(user)){
                                <button class="btn btn-outline-success btn-xs" (click)="updateUserActive(user,true)"><i
                                        class="fa fa-user-check"></i>
                                </button>
                                }
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            }

            <div class="row"><span class="ml-1 p-2">Total: {{userData?.length}}</span></div>

        </div>
    </div>
    }


    <!-- formulario de usuario  -->
    @if(btnUser){
    <div class="panel-body ">
        <form [formGroup]="formUser">
            <div class="row">

                <div class="col-12  col-sm-12 col-dm-7 col-lg-6 col-xl-6">
                    <div class="row">
                        <div class="col-12  col-sm-8 col-dm-9  mail-user ">
                            <label>Correo</label>

                            <div class="input-group input-group">
                                <input class="form-control  "
                                    [class.is-invalid]="formUser.get('mail').invalid && formUser.get('mail').touched"
                                    [class.is-valid]="formUser.get('mail').valid&& !formUser.get('mail').touched"
                                    placeholder="Correo" formControlName="mail" name="mail"
                                    (click)="formUser.get('mail').markAsUntouched();formUser.get('mail').setErrors(null)"
                                    tabindex="1" (focusout)="onExit()">

                                <span class="input-group-text">{{'@'}}</span>
                                <select class="form-control form-select rounded-border" formControlName="domains"
                                    name="domains" (click)="formUser.get('domains').markAsUntouched();" tabindex="2">
                                    <option value="" disabled selected>Dominio</option>
                                    @for(items of dataDomains;track items._id){
                                    @if(items.isActive){
                                    <option [ngValue]="items.name">{{items.name}}</option>
                                    }
                                    }
                                </select>
                            </div>
                            @if(formUser.get('mail')?.errors?.customError){
                            <div class="text-danger"><small>Correo registrado</small></div>
                            }
                            @if(formUser.get('mail')?.errors?.required&& formUser.get('mail').touched){
                            <div class="text-danger">
                                <small>Nombre de usuario es requerido</small>
                            </div>
                            }
                            @if(formUser.get('mail')?.errors?.minlength && formUser.get('mail').touched){
                            <div class="text-danger">
                                <small>Nombre de usuario mínimo 6 caracteres</small>
                            </div>
                            }

                        </div>

                        <div class="col-12  col-sm-4 col-dm-4">
                            <label for="rol">Rol</label>
                            <select class="form-control form-select"
                                [class.is-invalid]="formUser.get('role').invalid&&formUser.get('role').touched"
                                formControlName="role" name="role" (click)="formUser.get('role').markAsUntouched();"
                                tabindex="3">
                                <option value="" selected>Todos los Roles</option>
                                @for(items of role;track items._id; let idx = $index){
                                @if(privilegesRolesSelect(items.level)&&items.isActive||titleUser==='Visualizar Usuario'){
                                <option [ngValue]="items._id">{{items.description }}</option>
                                }
                                }
                            </select>
                            @if(formUser.get('role')?.errors?.required && formUser.get('role').touched){
                            <div><small class="text-danger">Rol Requerido.</small></div>
                            }
                        </div>

                        <div class="form-group mt-2 ml-2 col-6 col-sm-5 col-md-4">
                            <label>Fecha Expiración</label>
                            <div class="input-group ">
                                <input class="form-control" placeholder="yyyy-mm-dd"
                                    [class.is-invalid]="formUser.get('expiration').invalid && formUser.get('expiration').touched"
                                    formControlName="expiration" name="expiration" ngbDatepicker #d="ngbDatepicker"
                                    tabindex="4" [readonly]="titleUser==='Visualizar Usuario'">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                        [disabled]="titleUser==='Visualizar Usuario'" type="button">
                                        <i class="fa fa-calendar"></i></button>
                                </div>
                            </div>
                            @if(formUser.get('expiration')?.errors?.required&& formUser.get('expiration').touched){
                            <div class="text-danger">
                                <small>Entidad es requerida</small>
                            </div>
                            }
                        </div>


                        <div class="form-group mt-2 ml-3 col-6 col-dm-8  ">
                            <label>{{ formUser.get('isActive').value ? 'Activo'
                                : 'Inactivo' }}</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" formControlName="isActive"
                                    name="isActive">
                            </div>
                        </div>
                    </div>

                </div>
                <!--otra mitad--><!-- app.component.html -->

                <div class="col-12  col-sm-12 col-dm-7 col-lg-6 col-xl-6 ">
                    <label>Entidades</label>
                    <ng-select class="dark-mode" #ngSelect [items]="entitiesAll" [multiple]="true" [selectableGroup]="true" [hideSelected]="true"
                    bindLabel="name" groupBy="country" [closeOnSelect]="false" [dropdownPosition]="'bottom'"
                    bindValue="_id" formControlName="entities" placeholder="Seleccionar Entidad" tabindex="3"
                     [selectableGroupAsModel]="false" 
                    [readonly]="titleUser==='Visualizar Usuario'"
                    (click)="onClickSelect()" 
                    (open)="onDropdownUser()"
                    (remove)="onDropdownUser()"
                    (clear)="onDropdownUser()"
                    (add)="onDropdownUser()"
                    (change)="onChangeSelect()"
                    (focusout)="onExitSelect()"  >               
                   
                             
                    @if(formUser.get('entities').value.length<userAdmin.entities.length&&titleUser==='Nuevo Usuario'||
                        titleUser==='Actualizar Usuario'&&userAdmin.entities.length>formUser.get('entities').value.length-formEntitiesTemp.length){
                        <ng-template ng-header-tmp>
                           
                        <button (click)="selectAll()" class="btn btn-xs btn-outline-primary">Todas las entidades </button>
                        </ng-template>
                    }
           

                 

                    <ng-template ng-footer-tmp>Entidades seleccionadas: {{ formUser.get('entities').value.length }} </ng-template>

                </ng-select>
              
                    @if(formUser.get('entities')?.errors?.required&& formUser.get('entities').touched){
                    <div class="text-danger"><small>Entidad es requerida</small></div>
                    }
                </div>
            </div>
        </form>

        <div class="mt-5">

            <button (click)="btnUser=false;titleUser='Gestión de Usuarios';"
                class="btn btn-success btn-sm" tabindex="6">
                <i class="fas fa-arrow-left"></i>&nbsp;Atras
            </button>&nbsp;
            @if(formUser.get('isActive')?.enabled&&privilegesUserAdmin()&&titleUser==='Actualizar Usuario'){
            <button (click)="submitUpdate(id)" class="btn btn-primary btn-sm" tabindex="4">
                <i class="fas fa-save"></i>&nbsp;Guardar
            </button>
            }
            @if(!formUser.get('isActive')?.enabled&&privilegesUserAdmin()&&titleUser==='Nuevo Usuario'){
            <button (click)="submitNew()" class="btn btn-primary btn-sm" tabindex="5">
                <i class="fas fa-save"></i>&nbsp;Guardar
            </button>
            }

        </div>
    </div>
    }
</div>
</div>
}