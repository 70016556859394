<!-- BEGIN page-header -->
<h1 class="page-header"><i class="fas fa-chart-line"></i> Presupuesto<small> Generar presupuesto {{'year'}}</small></h1>

<!-- END page-header -->

<!-- BEGIN panel -->
<div class="panel " data-sortable-id="ui-widget-16">
    <div class="panel-heading ">
    </div>
    <div class="panel-body p-4">
        <div class="row">
            <div class="row mb-3 mt-2">
                <div class="col-4 col-md-4">
                    <input class="form-control" type="text" [(ngModel)]="txtFind"
                        placeholder="Buscar por diverso, funcionario, hijo" (keyup)="onKeyUp()"
                        (keydown)="onKeydown($event)" />
                </div>
                <div class="col-2 col-md-2">
                    <button class="btn btn-primary btn-xs" (click)="getChildren()"><i class="fa fa-search p-2"></i>
                        Buscar&nbsp;&nbsp;</button>
                </div>
                <div class="col-6 col-md-6 d-flex justify-content-end">
                    <button class="btn btn-success btn-xs " (click)="UpdatePremiumChildren()">Actualizar
                        Prima</button>&nbsp;
                    <button class="btn btn-success btn-xs " (click)="openModalChildren()">Agregar
                        Prima</button>
                </div>
            </div>
            <div class=" mt-4">
                <div class="table-responsive table-data ">
                    <table class="table table-striped  table-sm ">
                        <thead>
                            <tr>
                                <th>N° </th>
                                <th>Diverso</th>
                                <th>Nombre Funcionario</th>
                                <th>Nombre Hijo</th>
                                <th>Edad</th>
                                <th>Prima Anual</th>
                                <th><span class="d-flex justify-content-center"><i class="fa fa-cogs"></i></span></th>
                            </tr>
                        </thead>
                        @if(dataDiverse?.length>0){
                        <tbody>
                            @for(d of dataChildren;track $index; let idx = $index){
                            <tr>
                                <td>{{idx+1}}</td>
                                <td>{{d.diverse}}</td>
                                <td>{{d.employee}}</td>
                                <td>{{d.names}}</td>
                                <td>{{d.age}}</td>
                                <td>${{d.computed_premium| number:'1.0-0'}}</td>
                                <td>

                                    <span class="d-flex justify-content-center">
                                        <button type="button" class="btn btn-outline-primary btn-xs "
                                            (click)="setFormChildren(d);"><i
                                                class="fa fa-pencil-alt"></i></button>&nbsp;

                                        <button type="button" class="btn btn-outline-danger btn-xs " placement="top"
                                            ngbTooltip="Eliminar" (click)="deleteChildren(d)"><i
                                                class="fa fa-trash"></i></button>
                                    </span>
                                </td>

                            </tr>
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colspan="10">{{dataChildren?.length}} en Total</th>
                            </tr>
                        </tfoot>
                        }
                    </table>
                    @if(dataChildren?.length===0){
                    <div class="m-l-10 mb-2">
                        <em>Sin registros</em>
                    </div>
                    }
                </div>
            </div>

        </div>
    </div>
    <div class="hljs-wrapper">
        <pre><code class="html" data-url="../assets/data/ui-widget-boxes/code-16.json"></code></pre>
    </div>
</div>
<!-- END panel -->






<!--modal children-->

<ng-template #modal_children let-c="close" let-d="dismiss" let-modal>

    <div class="modal-header">
        <h5 class="modal-title">{{modalHeaderChildren}}</h5>
        <button (click)="d('Cross click')" type="button" class="btn-close bt-xs" data-dismiss="modal" tabindex="7">
            <span>&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="panel-body panel-form">
            <form [formGroup]="formChildren">
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Diverso</label>
                    <div class="col-md-8">
                        <ng-select #ngSelect [items]="dataListDiverse" [selectableGroup]="true" [hideSelected]="true"
                            bindLabel="nameDiverse" [closeOnSelect]="true" [dropdownPosition]="'bottom'"
                            bindValue="diverse" placeholder="Seleccionar diverso" formControlName="employee_code"
                            name="employee_code" tabindex="1">
                        </ng-select>
                        @if(formChildren.get('employee_code')?.errors?.required &&
                        formChildren.get('employee_code').touched){
                        <div><small class="text-danger">Diverso es requerido.</small></div>
                        }
                    </div>
                </div>&nbsp;
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Nombre Hijo</label>
                    <div class="col-md-8">
                        <input type="text" #nameInput [class.is-invalid]="false" formControlName="names" names="names"
                            class="form-control" tabindex="2" />
                        @if(formChildren.get('names')?.errors?.required && formChildren.get('names').touched){
                        <div><small class="text-danger">Nombre del hijo es requerido.</small></div>
                        }
                    </div>
                </div>&nbsp;
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Fecha de Nacimiento</label>
                    <div class="col-md-8">
                        <input type="date" name="" id="" class="form-control" formControlName="birthday">
                        <!-- <div class="input-group">
                            <input class="form-control" placeholder="yyyy-mm-dd"
                                [class.is-invalid]="formChildren.get('birthday').invalid && formChildren.get('birthday').touched"
                                formControlName="birthday" name="birthday" ngbDatepicker #datepicker="ngbDatepicker"
                                tabindex="3">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()"
                                    type="button" tabindex="4">
                                    <i class="fa fa-calendar"></i>
                                </button>
                            </div>
                        </div> -->
                        @if(formChildren.get('birthday')?.errors?.required && formChildren.get('birthday').touched){
                        <div><small class="text-danger">Tipo de diverso es requerido.</small></div>
                        }
                        <!-- <div *ngIf="formChildren.controls['birthday'].hasError('dateInvalid')">
                            La fecha no puede ser posterior a hoy.
                          </div> -->

                        @if(formChildren.controls['birthday'].hasError('dateInvalid')){
                        <div><small class="text-danger">La fecha no puede ser posterior a hoy.</small></div>
                        }

                    </div>
                </div>

            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="d('Cross click')" tabindex="6"><i
                class="fas fa-times"></i>&nbsp; Cerrar</button>
        @if(modalHeaderChildren==='Nuevo'){
        <button type="button" class="btn btn-primary btn-sm" (click)="submitChildrenNew()"
            [disabled]="formChildren.pristine&&!formChildren.valid||!formChildren.valid" tabindex="5"><i
                class="fas fa-save"></i>&nbsp;Guardar</button>
        }
        @if(modalHeaderChildren==='Modificar'){
        <button type="button" class="btn btn-primary btn-sm" (click)="submitChildrenUpdate()"
            [disabled]="formChildren.pristine||!formChildren.valid" tabindex="5"><i
                class="fas fa-save"></i>&nbsp;Guardar</button>
        }

    </div>
</ng-template>
<!--end Children-->