import { Component, ViewChild,Input,Output, EventEmitter, effect, inject, ViewChildren, QueryList, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { EmployeeModel } from 'app/model/employee.model';
import { BudgetGenerateService } from '@services/budget-generate.service';
import { NgbModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserService } from '@services/core/user.service';
import { Entity } from '../../../budget-generate.interface';
import Swal from 'sweetalert2';
import { CommonModule } from '@angular/common';
import { ContextMenuComponent } from 'app/components/contex-menu/context-menu.component';


@Component({
  selector: 'app-setting-field-salary',
  templateUrl: 'setting-field-salary.component.html',
  styleUrl: 'setting-field-salary.component.css',
  standalone: true,
  imports: [CdkDropList,
    CdkDrag,
    MatTableModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ContextMenuComponent, CommonModule
  ],
  providers: [NgbModal],
})
export class SettingFieldSalaryComponent {
  @Input() employeeType!: number;
  @Output() activeChange = new EventEmitter<boolean>();
  @ViewChild('table', { static: true }) table: MatTable<any>;
  @ViewChildren('input') inputs!: QueryList<ElementRef>;
  @ViewChild('modal_account', { static: true }) modalAccount: any;
  @ViewChild('nameInput') nameInput: ElementRef;
  @ViewChild('ngSelect', { static: false }) ngSelectAssinet: NgSelectComponent;
  @ViewChild(ContextMenuComponent) contextMenu!: ContextMenuComponent;
  private modalRef: NgbModalRef;
  public filter: EmployeeModel = new EmployeeModel();
  public salaryService = inject(BudgetGenerateService);
  private _identityService = inject(UserService);
  private entitySelected: Entity;
  public dependentAccountPlanVersion;
  public independentAccountPlanVersion;
  public accountPlanVersion;
  private timeout: any;
  public dataSource;
  public formAccountPlanVersion: FormGroup;
  public account;
  displayedColumns: string[] = ['order',
    'is_visible',
    'account_code',
    'display_name',
    'fmr_applied',
    'is_taxable',
    'type_input',
    'hint',
    'calculation',
    'block',
    'missionary_block',
    'setting'];
  constructor(private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
  ) {

    effect(() => {
      this.entitySelected = this._identityService.sharingEntity();
      if (this.entitySelected && Object.keys(this.entitySelected).length > 0) {

        this.ngOnInit()
      }

    })


  }


  /**crear formulario reactivo diverso*/
  createFormAccount() {
    this.formAccountPlanVersion = this.formBuilder.group({
      account_code: [undefined, [Validators.minLength(3)]],
      display_name: ['', [Validators.required, Validators.minLength(3)]],
      hint: [''],
      calculation: [''],
      entity_code: ['', [Validators.required, Validators.minLength(1)]],
      type_input: ['text', [Validators.required]],
      employee_type: ['', [Validators.required]],
      year: ['', [Validators.required]],
      version: ['', [Validators.required]],
    });
  }


  /**oculta el menu contextual al hacer scroll */
  onTableScroll() {
    this.contextMenu.hide();
    this.dataSource.forEach(element => {
      element.isEditingHint = false;
      element.isEditingType = false;
      element.isEditingCal = false;
    });
  }

  /**agrega al menu contextual */
  block(account){
    return {
      label: account.block ? 'Desbloquear Edición' : 'Bloquear Edición',
      icon: account.block ? 'fa-solid fa-lock-open' : 'fa-solid fa-lock',
      onClick: () => this.toggleBlockAccount(account)
    };
  }
  
  /**agrega al menu contextual */
  blockMissionary(account){
    return {
      label: account.missionary_block ? 'Desbloquear Edición a Misioneros' : 'Bloquear Edición a Misioneros',
      icon: account.missionary_block ? 'fa-solid fa-lock-open' : 'fa-solid fa-lock',
      onClick: () => this.toggleBlockMissionary(account)
    };
  }

  accountType(account,actions){
    if(account.account_type!=0){
      actions[actions.length]={ 
        label: 'Eliminar',
        icon:'fa-solid fa-trash',  
        onClick: () => this.deleteAccountPlan(account) 
      };
    }
    return actions  
 
  }

  toggleBlockAccount(account) {   
    account.block= !account.block; 
    this.updateAccountPlanCheck(account,'block',account.block)
   
  }
  
  toggleBlockMissionary(account) {
    account.missionary_block = !account.missionary_block;
    this.updateAccountPlanCheck(account,'missionary_block',account.missionary_block)    
    
  }

  /**activa el menu contextual al click derecho  */
  onRightClick(event: MouseEvent, account) {
    let actions = [
      { label: 'Agregar Columna de Calculo',icon:'fa-solid fa-calculator',  onClick: () => this.newColumn(account) }
    ];
    actions[1]=this.block(account);
    actions[2]=this.blockMissionary(account);
    actions=this.accountType(account,actions);
    event.preventDefault();
    const offsetX = event.offsetX;
    const offsetY = event.offsetY;
    const targetElement = event.currentTarget as HTMLElement;
    const adjustedX = offsetX + targetElement.getBoundingClientRect().left;
    const adjustedY = offsetY + targetElement.getBoundingClientRect().top;
    this.contextMenu.actions = actions;
    this.contextMenu.show(adjustedX, adjustedY);
  }


 randomNumber() {
    return Math.floor(Math.random() * 1000) + 1;
  }

  /** Genera un código único que no existe en los registros */
generateCode() {
  let code;
  do {
    code = 'aux_' + this.randomNumber(); 
  } while (!this.findAcountPlan(code)); 

  return code;
}

  /**nueva columna calculada */
  newColumn(account) {
    let column = {}
    let code = this.generateCode();
    column['account_code'] = code;
    column['entity_code'] = account.entity_code;
    column['hint'] = 'Columna de calculo de ' + account.display_name;
    column['display_name'] = 'Columna de calculo de ' + account.display_name;
    column['employee_type'] = account.employee_type;
    column['year'] = account.year;
    column['version'] = account.version;
    column['account_type'] = 2; 
    column['is_taxable'] = 0;
    column['fmr_applied'] = 0;
    column['block'] = 0;
    column['missionary_block'] = 0;
    column['order'] = account.order ;
    this.salaryService.saveAccountPlanVersion(column).subscribe(res => {
      this.getAccountPlanVersion();
      this.notification('Cuenta guardada exitosamente');
    })
  }



  // Cierra el menú si haces clic en cualquier otro lugar
  @HostListener('document:click')
  clickAnywhere() {
    if (this.contextMenu.visible) {
      this.contextMenu.hide();
    }
  }


  /**Activa el input y le da el foco */
  enableEditing(element, field) {
    element['isEditing' + field] = true;
    this.cdr.detectChanges();
    setTimeout(() => {
      const input = this.inputs.toArray()[0];
      if (input && input.nativeElement) {
        input.nativeElement.focus();
      }
    }, 0);
  }


  /**Ajusta el alto de scroll y la posicion de del input en relacion a la posicion del contenedor */
  autoGrow(event: Event): void {
    clearTimeout(this.timeout);
    const textArea = event.target as HTMLTextAreaElement;
    const parent = textArea.parentElement;
    const topParent = parent.getBoundingClientRect()
    const newTop = topParent.top - 16;
    textArea.style.height = textArea.scrollHeight + 2 + 'px';
    textArea.style.top = newTop + 'px';
    textArea.style.left = topParent.left + 'px';
  }

  /**Ajusta el alto y la posicion de del input a la posicion del contenedor */
  autoTop(event: Event): void {
    clearTimeout(this.timeout);
    const textArea = event.target as HTMLTextAreaElement;
    const parent = textArea.parentElement;
    const topParent = parent.getBoundingClientRect()
    const newTop = topParent.top - 16;
    textArea.style.height = 55 + 'px';
    textArea.style.top = newTop + 'px';
  }


  /**mueve la fila y ejecuta el metodo para actualizar el nuevo orden */
  drop(event: CdkDragDrop<string>) {
    const previousIndex = this.dataSource.findIndex(d => d === event.item.data);
    moveItemInArray(this.dataSource, previousIndex, event.currentIndex);
    this.updateOrder(this.dataSource)
    this.table.renderRows();
    this.notification('Datos Actualizados');
  }

  getAccountPlanWithTime() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getAccountPlanVersion();
    }, 5000);
  }

  /**Metodo para actualizar el orden del plan de cuenta */
  updateOrder(data) {
    data.map((account, index) => {
      let ordenAccount = this.setAccount(account.account_code)
      ordenAccount.order = index + 1
      this.salaryService.updateAccountPlanVersion({ id: account.id, order: index + 1 }).subscribe(res => {

      })
    })
  }


  setAccount(account_code) {
    return this.dataSource.find(acc => acc.account_code == account_code);
  }


  /**Actualiza el account plan version*/
  updateAccountPlan(data, field, newValue) {
    clearTimeout(this.timeout);
    let account = this.setAccount(data.account_code)
    if (account[field] != newValue) {
      account[field] = newValue
      this.salaryService.updateAccountPlanVersion({ id: data.id, [field]: newValue }).subscribe(res => {
        this.notification('Datos Actualizados');
      })
    }
  }


    /**Actualiza el account plan version*/
    updateAccountPlanCheck(data, field, newValue) {
        this.salaryService.updateAccountPlanVersion({ id: data.id, [field]: newValue }).subscribe(res => {
          this.notification('Datos Actualizados');
        })
    }



  /**Elimina un account plan version*/
  deleteAccountPlan(params) {
    Swal.fire({
      icon: 'warning',
      title: 'Alerta ',
      text: '¿Desea eliminar la cuenta ' + params.account_code + ' ' + params.display_name + '?',
      toast: false,
      position: 'center',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      timer: null,
      timerProgressBar: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.salaryService.deleteAccountPlanVersion({ id: params.id }).subscribe(res => {
          this.notification('Cuenta ' + params.account_code + ' ' + params.display_name + 'eliminada ');
          this.getAccountPlanVersion();
        })

      }
    })



  }

  ngOnInit() {
    /**aqui podemos recibir los valores desde la tabla budget_version */
    this.filter.year = 2024;
    this.filter.version = 1;
   // this.employeeType = 1;
    this.getAccountPlanVersion();
    this.createFormAccount();
    this.formAccountPlanVersion.get('year').patchValue(this.filter.year);
    this.formAccountPlanVersion.get('version').patchValue(this.filter.version);
    this.formAccountPlanVersion.get('entity_code').patchValue(this.entitySelected?.code);
    this.formAccountPlanVersion.get('employee_type').patchValue(1);
    this.getAccount()
  }




  /**trae el plan de cuentas */
  getAccountPlanVersion() {
    delete this.filter.type_id;
    this.salaryService.getAccountPlanVersion(this.where()).subscribe(res => {
      this.accountPlanVersion = res
      this.dependentAccountPlanVersion = res.filter(r => r.employee_type === 1)
      this.independentAccountPlanVersion = res.filter(r => r.employee_type === 2)
      this.dataSource = this.dependentAccountPlanVersion;

    })
  }


  /**trae las cuentas */
  getAccount() {
    delete this.filter.type_id;
    this.salaryService.getAccount().subscribe(res => {
      this.account = this.mapSelect(res)
    })
  }

  where() {
    let queryParams = '';
    if (this.filter) {
      queryParams = Object.keys(this.filter)
        .map(key => `${this.filter[key as keyof EmployeeModel]}`)
        .join('/');
    }
    return queryParams ? `${queryParams}` : '';
  }

  /*abre los modales */
  open(content, size?) {
    this.modalRef = this.modalService.open(content, size);
  }

  /*cierra los modales */
  close() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  /**abre el modal de agregar cuenta */
  openModalAccount() {
    this.open(this.modalAccount)
  }

  changeEmployeeType() {
    this.dataSource = this.employeeType == 1 ? this.dependentAccountPlanVersion : this.independentAccountPlanVersion;
    this.formAccountPlanVersion.get('employee_type').patchValue(this.employeeType);
  }

  /**busca los las cuentas q no existen  */
  findAcountPlan(code): boolean {
    let r = false;
    try {
      r = !this.accountPlanVersion.some((a) => a.account_code == code);
    }
    catch {
    }
    return r
  }


  /**busca los las cuentas q existen  */
  findAcount(code): boolean {
    return this.dataSource.some((a) => a.account_code == code);
  }



  /*mapea los datos del ng-select deshabilita los registros q ya entan registrado el diverso de empleado */
  mapSelect(data) {
    let newData = data.map((d) => {
      const name = d.name
      const isActive = this.findAcountPlan(d.code)
      return {
        code: d.code,
        nameCode: d.code + ' - ' + name,
        name: name,
        disabled: !isActive,
        isActive: isActive
      }
    });
    return newData
  }


  /**al cerrar el ngselect le pasa el foco y el valor al input de displayName */
  closeNgSelect(value) {
    this.formAccountPlanVersion.get('display_name').patchValue(value.selectedItems[0]?.value?.name);
    this.nameInput.nativeElement?.focus();
    this.nameInput.nativeElement?.select();
  }

  resetForm() {
    this.formAccountPlanVersion.patchValue({
      account_code: undefined,
      display_name: undefined,
      hint: '',
      calculation: ''
    });
    this.formAccountPlanVersion.get('account_code')?.markAsPristine();
    this.formAccountPlanVersion.get('display_name')?.markAsPristine();
    this.formAccountPlanVersion.get('hint')?.markAsPristine();
    this.formAccountPlanVersion.get('calculation')?.markAsPristine();
    this.formAccountPlanVersion.get('display_name')?.markAsUntouched();


  }




  /**al cerrar el ngselect le pasa el foco y el valor al input de displayName */
  submitNewAccount() {
    if (this.formAccountPlanVersion.valid) {
      this.salaryService.saveAccountPlanVersion(this.formAccountPlanVersion.value).subscribe(res => {
        this.getAccountPlanVersion();
        this.close();
        this.resetForm();
        this.notification('Cuenta guardada exitosamente');
      })
    }
  }


  notification(title) {
    const Toast = Swal.mixin({
      icon: 'success',
      title: title,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2500,
      timerProgressBar: true,
    });
    Toast.fire();
  }



}


