import { environment } from '../../../environments/environment';



let localhost = {
	url: "http://localhost:3700/api/",
	url_app: "http://localhost:3700/api-app/",
	url_auth: 'http://localhost:3700/api-auth/',
	url_api_auth: 'https://api-auth.adventistas.cl/api-auth/',
	version: "1.1.0.18"
}

let dev = {
	url:  'https://api-presupuesto-dev.adventistas.cl/api/',
	url_app: "https://api-presupuesto-dev.adventistas.cl/api-app/",
	url_auth: 'https://api-presupuesto-dev.adventistas.cl/api-auth/',
	url_api_auth: 'https://api-auth.adventistas.cl/api-auth/',
	version: "1.1.0.18"
}

let test = {
	url:  'https://api-presupuesto-staging.adventistas.cl/api/',
	url_app: "https://api-presupuesto-staging.adventistas.cl/api-app/",
	url_auth: 'https://api-presupuesto-staging.adventistas.cl/api-auth/',
	url_api_auth: 'https://api-auth.adventistas.cl/api-auth/',
	version: "1.1.0.18"
}

let prod = {
	url:  'https://api-presupuesto.adventistas.cl/api/',
	url_app: "https://api-presupuesto.adventistas.cl/api-app/",
	url_auth: 'https://api-presupuesto.adventistas.cl/api-auth/',
	url_api_auth: 'https://api-auth.adventistas.cl/api-auth/',
	version: "1.1.0.18"
}

if (environment.env != 'Production'){
	console.log("Enviroment: " + environment.env)
}

export var Global = environment.env == 'Staging' ? test : (environment.env== 'Production'?prod:(environment.env== 'Development'?dev:localhost))
