export class EmployeeModel {
    id: number;
    employee_code: number;
    names: string;
    surname: string | null;
    lastname: string | null;
    type_employee: string;
    type_id: number;
    department_code: string;
    department_name: string;
    fmr: number;
    apv: number;
    pdr: number;
    year: number;
    entity_code: number;
    totales: number;
    version: number;
    accounts: Account[];
  }
  
  export interface Account {
  }
  