import { Injectable } from '@angular/core';
import { EncryptStorage } from 'encrypt-storage';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private encryptStorage 
  constructor() {
    this.encryptStorage = new EncryptStorage('{![G!R*mTLi,c&6]*.iP3?/DL,wu%Z!QVUh8qfxbiBrx{)n+)/-{{SABS6t?6(QUKVFUGu6TXF:;$D?2F=Eg9iHJk+S$&u**Yzp,ymk)tj(,e.f%.LbfVN8H', {
      storageType: 'sessionStorage',
    })
   }
  // Set the json data to local
  setJsonValue(key: string, value: any) {
    this.encryptStorage.setItem(key, value);
  }
  // Get the json value from local
  getJsonValue(key: string) {
    return this.encryptStorage.getItem(key);
  }// Clear the local
  clearStorage() {
    return this.encryptStorage.clear();
  }
}
