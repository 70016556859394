<div class="row mb-3 mt-2">
  <div class="col-8 col-md-3">
    <button class="btn btn-secondary text-white m-r-10" (click)="activeChange.emit(false)">Volver</button>
   </div>

  <div class="col-4 col-md-9 d-flex justify-content-end">
    <button class="btn btn-success text-white m-r-10" (click)="openModalAccount()">Agregar Cuenta</button>
  </div>
</div>

<div [ngStyle]="{'top.px': contextMenu.y, 'left.px': contextMenu.x, 'position': 'absolute'}">
  <app-context-menu #contextMenu></app-context-menu>
</div>


<mat-table #table [dataSource]="dataSource" class="mat-elevation-z8" cdkDropListData="dataSource" cdkDropList (cdkDropListDropped)="drop($event)"
  (scroll)="onTableScroll()" >
  <ng-container matColumnDef="order">
    <mat-header-cell *matHeaderCellDef style="flex: 0 0 65px;">Orden </mat-header-cell>
    <mat-cell    *matCellDef="let element" style="flex: 0 0 65px;"  (contextmenu)="onRightClick($event, element)">
      <span ><i class="fa-solid fa-grip-vertical"></i></span>&nbsp;&nbsp;
      <span>{{element.order}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="is_visible">
    <mat-header-cell *matHeaderCellDef style="flex: 0 0 65px;">Visible </mat-header-cell>
    <mat-cell *matCellDef="let element" style="flex: 0 0 65px;" (contextmenu)="onRightClick($event, element)">
      <input #is_visible class="form-check-input" type="checkbox" [checked]="element.is_visible"
        (change)="updateAccountPlan(element,'is_visible',is_visible.checked)"> </mat-cell>
  </ng-container>

  <ng-container matColumnDef="code">
    <mat-header-cell *matHeaderCellDef style="flex: 0 0 90px;"> Codigo </mat-header-cell>
    <mat-cell *matCellDef="let element" style="flex: 0 0 90px;"
      (contextmenu)="onRightClick($event, element)">{{element.code}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef style="flex: 0 0 210px;"> Nombre para Mostrar </mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="enableEditing(element)" class="editable-span text-nowrap"
      style="flex: 0 0 210px;" (contextmenu)="onRightClick($event, element)">
      @if(!element.isEditingName){
      <span (click)="enableEditing(element,'Name')">
        {{ element.name || '---' }}
      </span>
      }
      @if(element.isEditingName){
      <input #input type="text" class="form-control form-control-sm" [value]="element.name"
        (blur)="updateAccountPlan(element, 'name', input.value);element.isEditingName = false; element.name = input.value"
        (keydown.enter)="updateAccountPlan(element, 'name', input.value);element.isEditingName = false; element.name = input.value" />
      }
    </mat-cell>
  </ng-container>
  
  <ng-container matColumnDef="hint">
    <mat-header-cell *matHeaderCellDef style="flex: 0 0 250px;">Info de Cuenta</mat-header-cell>
    <mat-cell *matCellDef="let element;let i =index" (click)="enableEditing(element,'Hint')" class="editable-span"
      style="flex: 0 0 250px;" (contextmenu)="onRightClick($event, element)">
      @if(!element.isEditingHint){
      <span>
        {{ element?.hint?.slice(0,60)|| '-' }}
      </span>
      }
      @if(element.isEditingHint){
      <div class="textarea-container">
        <textarea #input class="editable-textarea form-control form-control-sm" [value]="element.hint"
          (blur)="updateAccountPlan(element, 'hint', input.value); element.isEditingHint = false;element.hint=input.value"
          (keydown.enter)="updateAccountPlan(element, 'hint', input.value); element.isEditingHint = false;element.hint=input.value"
          (focus)="autoGrow($event)" (input)="autoGrow($event)" rows="1">
          </textarea>
      </div>
      }
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="calculation">
    <mat-header-cell *matHeaderCellDef class="text-nowrap" style="flex: 0 0 250px;"> Formula</mat-header-cell>
    <mat-cell *matCellDef="let element;let i =index" (click)="enableEditing(element,'Cal')" class="editable-span"
      style="flex: 0 0 250px;" (contextmenu)="onRightClick($event, element)">
      @if(!element.isEditingCal){
      <span>
        {{ element?.calculation?.slice(0,60)|| '-' }}
      </span>
      }
      @if(element.isEditingCal){
      <div class="textarea-container">
        <textarea #input class="editable-textarea form-control form-control-sm" [value]="element.calculation"
          (blur)="updateAccountPlan(element, 'calculation', input.value); element.isEditingCal = false;element.calculation=input.value"
          (keydown.enter)="updateAccountPlan(element, 'calculation', input.value); element.isEditingCal = false;element.calculation=input.value"
          (focus)="autoGrow($event)" (input)="autoGrow($event)" rows="1">
          </textarea>
      </div>
      }
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="block">
    <mat-header-cell *matHeaderCellDef style="flex: 0 0 80px;"> Bloquear </mat-header-cell>
    <mat-cell *matCellDef="let element" style="flex: 0 0 80px;" (contextmenu)="onRightClick($event, element)">
      <input #block class="form-check-input" type="checkbox" [checked]="element.block"
        (change)="updateAccountPlan(element,'block',block.checked)"></mat-cell>
  </ng-container>
  <ng-container matColumnDef="missionary_block">
    <mat-header-cell *matHeaderCellDef style="flex: 0 0 120px;"> Bloquear para Misioneros</mat-header-cell>
    <mat-cell *matCellDef="let element" style="flex: 0 0 80px;" (contextmenu)="onRightClick($event, element)">
      <input class="form-check-input" #missionary_block type="checkbox" [checked]="element.missionary_block"
        (change)="updateAccountPlan(element,'missionary_block',missionary_block.checked)"></mat-cell>
  </ng-container>
 
  <ng-container matColumnDef="setting">
    <mat-header-cell *matHeaderCellDef style="flex: 0 0 100px;"> <i class="fa-solid fa-gear"></i></mat-header-cell>
    <mat-cell *matCellDef="let element" style="flex: 0 0 100px;" (contextmenu)="onRightClick($event, element)">
      @if(element.account_type!=0){
      <button class="btn btn-outline-danger btn-xs" (click)="deleteAccountPlan(element)"><i
          class="fa-solid fa-trash"></i>
      </button>
    }
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragData]="row"></mat-row>
</mat-table>

<br>



<ng-template #modal_account let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Agregar Item</h5>
    <button (click)="d('Cross click')" type="button" class="btn-close bt-xs" data-dismiss="modal" tabindex="7">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formItemsTravel">
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Cuenta</label>
        <div class="col-md-8">
          <ng-select #ngSelect [hideSelected]="true" [items]="account" bindLabel="nameCode" [closeOnSelect]="true"
            [dropdownPosition]="'bottom'" bindValue="code" placeholder="Escribe una cuenta o codigo"
            formControlName="code" name="code"  tabindex="1"
            (close)="closeNgSelect(ngSelect)">
          </ng-select>
          @if(formItemsTravel.get('code')?.errors?.required &&
          formItemsTravel.get('code').touched){
          <div><small class="text-danger">Diverso es requerido.</small></div>
          }
        </div>
      </div>&nbsp;
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Nombre para mostrar</label>
        <div class="col-md-8">
          <input type="text" #nameInput
            [class.is-invalid]="formItemsTravel.get('name').invalid&&formItemsTravel.get('name').touched"
            (click)="formItemsTravel.get('name').markAsUntouched();"
            (focus)="formItemsTravel.get('name').markAsUntouched();" formControlName="name"
            name="name" class="form-control" tabindex="2" />
          @if(formItemsTravel.get('name')?.errors?.required &&
          formItemsTravel.get('name').touched){
          <div><small class="text-danger">Campo es requerido.</small></div>
          }
        </div>
      </div>&nbsp;
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Inf Campo</label>
        <div class="col-md-8">
          <input type="text"
            [class.is-invalid]="formItemsTravel.get('hint').invalid&&formItemsTravel.get('hint').touched"
            (click)="formItemsTravel.get('hint').markAsUntouched();"
            (focus)="formItemsTravel.get('hint').markAsUntouched();" formControlName="hint" name="hint"
            class="form-control" tabindex="2" />
          @if(formItemsTravel.get('hint')?.errors?.required && formItemsTravel.get('hint').touched){
          <div><small class="text-danger">Campo es requerido.</small></div>
          }
        </div>
      </div>&nbsp;
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Tipo de Campo</label>
        <div class="col-md-8">
          <select
            [class.is-invalid]="formItemsTravel.get('type_input').invalid&&formItemsTravel.get('type_input').touched"
            (click)="formItemsTravel.get('type_input').markAsUntouched();"
            (focus)="formItemsTravel.get('type_input').markAsUntouched();" class="form-control form-select"
            formControlName="type_input" name="type_input" tabindex="3">
            <option value="text">Text</option>
            <option value="checkbox">Checkbox</option>
          </select>
          @if(formItemsTravel.get('type_input')?.errors?.required &&
          formItemsTravel.get('type_input').touched){
          <div><small class="text-danger">Tipo de diverso es requerido.</small></div>
          }
        </div>
      </div>&nbsp;
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Formula</label>
        <div class="col-md-8">
          <textarea #input formControlName="calculation" name="calculation" class="form-control form-control-sm"
            (focus)="autoGrow($event)" (input)="autoGrow($event)" rows="1">
        </textarea>
        </div>
      </div>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary btn-sm" (click)="d('Cross click'); resetForm()"><i
        class="fas fa-times"></i>&nbsp; Cerrar</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="submitNewItemsTravel()"
      [disabled]="formItemsTravel.pristine&&!formItemsTravel.valid||!formItemsTravel.valid"
      tabindex="4"><i class="fas fa-save"></i>&nbsp;Guardar</button>


  </div>

</ng-template>