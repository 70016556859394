import { Component,inject,effect,ViewChild} from '@angular/core';
import {  FormsModule, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2'
import { MaintenanceService } from './maintenance.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { UserService } from '@services/core/user.service';
import { AppComponent } from 'app/app.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AppSettings } from '@services/app-settings.service';
import { AppVariablesService } from '@services/app-variables.service';

@Component({
	selector: 'app-maintenance-users',
	standalone:true,
	imports:[ AppComponent,CommonModule,
		      NgSelectModule,
		      FormsModule,
		      ReactiveFormsModule,
		      NgbModule,
			],
	templateUrl: './maintenance-users.component.html',
	styleUrls: ['./maintenance-users.component.css'],
	providers: [ MaintenanceService]
})
export class MaintenanceUserComponent {
	public formUser: FormGroup
	public first: boolean = true;
	public order: boolean = true;
	public orderColName:string='role.description'
	public btnUser: boolean;
	public token;
	public titleUser: string;
	public titleMessage;
	public titleNotication;
	public userData;
	public txtSearch: string = '';
	public role = [];
	public userAdmin = {
		indentity: {
			mail: '',
			_id: ''
		},
		level: 4,
		entities: []
	}
	public formEntitiesTemp = [];
	public selectFilterCountry = 0;
	public selectFilterRol = '';
	public findMail: boolean = false;
	public id: string;
	selectedItems: any[] = [];//varible q tiene chec
	public country;
	public entitiesAll;//variable para almacenar todas las entidades 
	public entitiesAllTemp;//variable para almacenar todas las entidades y cambiara en funcion si la lista desplegable necesita alguna entidad
	public dataDomains;	
	isDarkMode
	private _identityService = inject(UserService)
	identity;
	swalBackground;
	swalTextColor;

	@ViewChild('ngSelect') ngSelect: NgSelectComponent;
	

	

	constructor(
		private _maintenanceService: MaintenanceService,
		private formBuilder: FormBuilder,
		private appVariablesService: AppVariablesService,
        public appSettings: AppSettings


	) {
		effect(() => {
			this.identity = this._identityService.sharingIdentity(); 
			if (this.identity && Object.keys(this.identity).length > 0){
				    this._maintenanceService.getRole().subscribe(reponse => {
					this.role = reponse;
					this.loadEntities();			
					this.getIdetityFront();
				});	  
			}					         	   
		})
	
	}


	public alertMessage = Swal.mixin({
		icon: 'success',
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 2000,
		timerProgressBar: true
	})
	


	ngOnInit() {
		this.appSettings.appDarkMode=localStorage['appDarkMode'];
				
		this.getFindUser();
		this.getDomains();			
		const fechaArray = ['10', '10', '1991'];
		const fechaObjeto = new Date(`${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`);
		this.first = true;
		this.btnUser = false;
		this.titleUser = "Gestión de Usuarios"
		this.formUser = this.formBuilder.group({
			mail: ['', [Validators.required, Validators.minLength(6)]],
			domains: ['', Validators.required],
			expiration: ['', [Validators.required]],
			role: ['', Validators.required],
			entities: [[], Validators.required],
			isActive: [true, Validators.required]
		});

	}


	/*Busca entidades del userAdmin por nombre y devuelve true si exite*/
	findEntitiesAdmin(entities): boolean {
		return this.userAdmin.entities.some((entity) => { return entity._id === entities })
	}


/**carga todas las entidades */
	async loadEntities() {
		let entitiesMethod;
		await this._maintenanceService.getEntities().subscribe(entities => {
			entitiesMethod = entities;
			this.entitiesAll = entitiesMethod.map(item => {
				const isActive = this.findEntitiesAdmin(item._id);
				return {
				  _id: item._id,
				  name: `${item.code} ${item.group} ${item.campus}`,
				  country: item.campus,
				  selected: false,
				  disabled: !isActive,
				  isActive: isActive
				};
			  });
			this.entitiesAllTemp = this.entitiesAll;
			this.country = this.groupByCountry(this.entitiesAll)
		});

	}

	groupByCountry(entities) {
		const countryUnique = entities.reduce((result, entity) => {
			const countryMethod = entity.country;
			if (!result.includes(countryMethod)) {
				result.push(countryMethod);
			}
			return result;
		}, []);
		return countryUnique.sort(); // Ordenar los campos de A a Z
	}

	/**Validar envio de cabecera para la api */
	validParams(params?) {
		let txtfind = this.txtSearch === '' ? 0 : this.txtSearch
		params = params ? params + '@' + this.formUser.get('domains').value : txtfind
		let query = params;
		query = query + '/' + this.selectFilterCountry
		if (this.selectFilterRol !== '') {
			query = query + '/' + this.selectFilterRol
		}
		return query
	}



	customError(response, params){
		if (response.length > 0 && params && this.titleUser==='Nuevo Usuario') {
			this.formUser.get('mail')?.setErrors({ customError: true });
		}		
	}


	async getFindUser(params?) {
			this.swalDarkMode()
			this.orderColName='role.description';
			this.order=true;
			let query = this.validParams(params);
			await this._maintenanceService.findUser(query).subscribe(response => {
				this.userData = !params ? response : this.userData;
				this.customError(response, params);
				Swal.close()				
				params = undefined
			});
			this.first = false;
	}


	getDomains() {
		this._maintenanceService.getdomains().subscribe(reponse => {
			this.dataDomains = reponse
			this.formUser.get('domains').patchValue('adventistas.org');
		});
	}

	extractDomain(mailParam) {
		const email = mailParam
		if (email) {
			const domain = email.split('@')[1];
			const user = email.split('@')[0];
			if (this.dataDomains.some(item => item.name === domain)) {
				this.formUser.get('domains').patchValue(domain);
				this.formUser.get('mail').patchValue(user);
			}
		}
	}

	onKeyUp() {
		if (this.txtSearch === '') {
			this.getFindUser();
		}
	}


	onKeydown(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			this.getFindUser();

		}
	}

/**busca el level  */
	getLevel(Level): number {
		let role = this.role.find(role => role._id === Level._id);
		role = role ? role.level : undefined;
		return role

	}


	/**seleccionar todas las entidades */
	selectAll(){
		if(this.titleUser === 'Actualizar Usuario'){
			this.entitiesAll=this.entitiesAllTemp	
			this.formUser.get('entities').patchValue(this.listEntitiesSelect(this.formEntitiesTemp, this.entitiesSelect(this.userAdmin.entities)));
		}
		if(this.titleUser === 'Nuevo Usuario'){
			this.formUser.get('entities').patchValue( this.entitiesSelect(this.userAdmin.entities));
		}
		this.ngSelect.close();
	}


	onClickSelect(){
		this.formUser.get('entities').markAsUntouched()		
	}


	/**al desplegar la lista del ng-select solo muestra las entidades del userAdmin */
	onDropdownUser()
	{	
		if (this.titleUser === 'Actualizar Usuario') {
			this.entitiesAll = this.userAdmin.entities;
		}		
	}	
 

	/*al salir de foco del campo select valida si esta vacio mensaje de error y carga las entidades del usario mas las selecionas*/

	onExitSelect() {
		if(this.formUser.get('entities').value.length===0){
			this.formUser.get('entities').markAsTouched()
			this.formUser.get('entities').setErrors({required:true})
		}
		if (this.titleUser === 'Actualizar Usuario') {
			this.entitiesAll=this.entitiesAllTemp					
		}	
	}



	onChangeSelect() {
		if (this.titleUser === 'Actualizar Usuario') {
			this.entitiesAll=this.entitiesAllTemp					
		}
	}	

	
	/*recibe las entidades del usuario a modificar y modifica la lista del select */

	listEntitiesSelect(idEntitiesSelect, idSeleted) {
		idEntitiesSelect=idEntitiesSelect.sort()
		let select=[...new Set([...idEntitiesSelect, ...idSeleted])]
		return select

	}

/**obtiene la identidad del usuario */
	async getIdetityFront() {		
	  if (this.identity && this.identity.role !== undefined) {
            this.userAdmin.level =this.getLevel(this.identity.role);
            this.userAdmin.indentity.mail = this.identity.mail;
            this.userAdmin.indentity._id = this.identity._id;
            this.userAdmin.entities = this.identity.entity.entities.map(item => ({
                _id: item._id,
                name: `${item.code} ${item.group} ${item.campus} `,
                country: item.campus, 
				selected: false,
				disabled: false,
				isActive: true
            }));
        }
    }

	/**busca en la entidades de la tabla si en campo alguna dice UCh y devuelve true en caso de conseguir */
	findUch(entities): boolean {
		return entities.some((entity) => { return entity.campus === 'UCh' })
	}


	findSelectAll(entities): boolean {
		return entities.some((entity) => { return entity.campo === 'Todas' })
	}

	/**ordenar datos */
	orderCol(col: string) {
		this.order = !this.order;
		this.orderColName = col;
		this.userData.sort((a: any, b: any) => {
			const valorA = this.getFieldValue(a, col);
			const valorB = this.getFieldValue(b, col);	
			let orderList=this.orderList(valorA,valorB)	
			return orderList;
		});
	}



orderList(valorA,valorB){
	let orderList
	if (this.order) {
		orderList = typeof valorA === 'string' ? valorA.localeCompare(valorB) : valorA - valorB;
	} else {
		orderList = typeof valorA === 'string' ? valorB.localeCompare(valorA) : valorB - valorA;
	}
	return orderList;
}


	getFieldValue(obj: any, path: string) {
		return path.split('.').reduce((value, key) => value[key], obj);
	}

	/*al salir de foco del campo mail busca si esta registrado el correo*/

	onExit() {
		if (this.titleUser === 'Nuevo Usuario' && this.formUser.get('mail').value !== '') {
			this.getFindUser(this.formUser.get('mail').value)
		}
		else if(this.formUser.get('mail').value===''){
			this.formUser.get('mail').setErrors({required:true})
		}
	}


	/*compara el level de usario select del level y devuelve true si tiene privilegio de acceso */
	privilegesRolesSelect(level): boolean {
		let provilegesActive = false
		if (this.userAdmin.level < level || this.userAdmin.level === 0) {
			provilegesActive = true
		}
		return provilegesActive
	}

	/*compara el nivel de usario con el nivel de usario de la tabla de usuario devuelve true si puede editar */

	privilegesLevel(levelTable): boolean {
		let provilegesActive = false
		let level = this.getLevel(levelTable.role)

		if (this.userAdmin.level < level || this.userAdmin.level === 0) {
			provilegesActive = true
		}
		return provilegesActive
	}

	/* devuelve las entidades que no tiene el userAdmin*/

	noEntitiesSelectAdmin() {
		return this.formEntitiesTemp.filter(entity => !this.userAdmin.entities.some(userEntity => userEntity._id === entity));
	}

	/*devuelve un arreglo de las entidades un usuario*/

	entitiesSelect(userTable) {
		let entitiesFiltered = this.entitiesAll.filter(entity => userTable.some(tableEntity => tableEntity._id === entity._id))
		let idEntitiesSelect = entitiesFiltered.map(entity => entity._id)
		return idEntitiesSelect.sort()

	}


	/*compara las entidades del usario con las entidades del usario de la tabla de usuario devuelve true si puede editar */

	privilegesEntities(userTable): boolean {
		let privilegesActive = false
		let filterEntities = this.userAdmin.entities.filter(entity => userTable.entity.entities.some(tableEntity => tableEntity._id === entity._id))
		privilegesActive = filterEntities.length > 0;
		if (this.userAdmin.level === 0) {
			privilegesActive = true
		}
		return privilegesActive
	}

	/*compara las entidades y level del usario con las entidades y level del usario de la tabla de usuario devuelve true si puede editar */

	privilegesUserAdmin(levelTable?): boolean {
		let provilegesActive = false;
		if (this.userAdmin.level <= 2) {
			if (levelTable !== undefined) {
				if (this.privilegesEntities(levelTable)) {
					provilegesActive = this.privilegesLevel(levelTable)
				}
			}
			else {
				provilegesActive = true
			}
		}
		else {
			provilegesActive = false
		}
		return provilegesActive
	}


	/**metodo para editar y agregar usuario usuario */

	showFormUser(params?) {
		if (!this.btnUser) {
			this.btnUser = true;
			this.entitiesAll = this.entitiesAllTemp
			this.titleUser = "Actualizar Usuario";
			this.formUser.get('role').enable()
			this.formUser.get('isActive').enable();
			this.formUser.get('role').patchValue(params.role._id);
			this.formUser.get('mail').patchValue(params.mail);
			this.extractDomain(this.formUser.get('mail').value)
			this.formUser.get('expiration').patchValue(this.setDate(params.expiration !== undefined ? params.expiration : undefined));				
			this.formUser.patchValue({ isActive: params.isActive });
			this.formUser.get('role').markAsUntouched();
			this.formUser.get('mail').markAsUntouched();
			this.formUser.get('entities').patchValue(this.entitiesSelect(params.entity.entities))
			this.formEntitiesTemp = this.formUser.get('entities').value
			this.formEntitiesTemp = this.noEntitiesSelectAdmin()
			if (this.privilegesUserAdmin(params)&& this.userAdmin.level === 0) {
				this.formUser.get('domains').enable()
				this.formUser.get('mail').enable()					
			}
			else {
				this.formUser.get('domains').disable()
				this.formUser.get('mail').disable()				
			}	
			this.formUser.get('entities').patchValue(this.listEntitiesSelect(this.formEntitiesTemp, this.formUser.get('entities').value));
			this.id = params._id;
		}
		else {
			this.entitiesAll = this.userAdmin.entities
			this.formUser.get('entities').patchValue([]);
			this.titleUser = "Nuevo Usuario";
			this.formUser.get('domains').patchValue('adventistas.org');
			this.formUser.get('expiration').patchValue(this.setDate());
			this.formUser.get('role').patchValue('');
			this.formUser.get('mail').patchValue('');
			this.formUser.patchValue({ isActive: true });
			this.formUser.get('isActive').disable();
			this.formUser.get('role').markAsUntouched();
			this.formUser.get('entities').markAsUntouched();
			this.formUser.get('mail').enable()
			this.formUser.get('mail').markAsUntouched();
			this.formUser.get('domains').enable()
		}
	}

	/**metodo para visualizar usuario */

	showFormUserView(params?) {
		if (!this.btnUser) {
			this.entitiesAll = this.entitiesAllTemp
			this.btnUser = true;
			this.titleUser = "Visualizar Usuario";
			this.extractDomain(params.mail)
			this.formUser.get('expiration').patchValue(this.setDate(params.expiration !== undefined ? params.expiration : undefined));
			this.formUser.get('role').patchValue(params.role._id);
			this.formUser.get('role').disable();
			this.formUser.patchValue({ isActive: params.isActive });
			this.formUser.get('isActive').disable();
			this.formUser.get('role').markAsUntouched();
			this.formUser.get('mail').disable()
			this.formUser.get('domains').disable()
			this.formUser.get('mail').markAsUntouched();
			this.formUser.get('mail').markAsPristine();
			this.formUser.get('entities').patchValue(this.entitiesSelect(params.entity.entities));
		}
	}


/**cambia la fecha en formato compatible para el mostrar campo de fecha */
	setDate(date?) {

		const expirationDate = date ? new Date(date) : new Date();
		let formatDate = {
			year: date ? expirationDate.getFullYear() : expirationDate.getFullYear() + 1,
			month: expirationDate.getMonth() + 1,
			day: date ? expirationDate.getDate() : expirationDate.getDate() + 1
		};
		return formatDate
	}
/**cambia la fecha en formato compatible para guardar la fecha en la base de datos */
	setDateSave(date) {
		let year = date.year;
		let month = date.month < 10 ? `0${date.month}` : date.month;
		let day = date.day < 10 ? `0${date.day}` : date.day;
		let newDate = `${year}-${month}-${day}`
		return newDate;
	}

	/**metodo de actualizar active */
	titleMessageAlert(params,paramActive){
		if (paramActive) {
			this.titleMessage = '¿Estas seguro de habilitar: ' + params.mail + '?'
			this.titleNotication = 'Usuario habilitado de manera Exitosa'
		}
		else {
			this.titleMessage = '¿Estas seguro de deshabilitar: ' + params.mail + '?'
			this.titleNotication = 'Usuario deshabilitado de manera Exitosa'
		}
	}

	swalDarkMode(){	
		if(this.appSettings.appDarkMode&&localStorage['appDarkMode'] === 'true'){
			this.swalBackground= '#333';
			this.swalTextColor='#fff';
		}
		else{			
			this.swalBackground='#fff';
			this.swalTextColor='#333';
		}
	}	

	/**metodo de actualizar active */
	updateUserActive(params, paramActive) {
		this.swalDarkMode();
		this.titleMessageAlert(params, paramActive)
		const loadingToast = Swal.fire({
			icon: 'warning',
			title: 'Alerta ',
			text: this.titleMessage,	
			toast: false,
			position: 'center',
			background: this.swalBackground,
			color:this.swalTextColor,	
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Aceptar',
			cancelButtonText: 'Cancelar',
			reverseButtons: true,
			timer: null,
			timerProgressBar: false
		}).then((result) => {
			if (result.isConfirmed) {
				this._maintenanceService.updateUser({ _id: params._id, isActive: paramActive }).subscribe(res => {
					this.getFindUser()
					Swal.close()
					const loadingToast = Swal.mixin({
						icon: 'success',
						title: this.titleNotication,toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 1500,
						timerProgressBar: true,
					})
					this.reload(params._id)
				}, (error) => {
					Swal.close()
					const loadingToast = Swal.mixin({
						icon: 'success',
						title: this.titleNotication,
						toast: true,	
						position: 'top-end',
						showConfirmButton: false,
						timer: 1500,
						timerProgressBar: true,
					})
				}
				);

			}

		});

	}


	/**recarga la pagina cuando se se actualiza el userAdmin */

	reload(params){
		if (params === this.userAdmin.indentity._id) {
			window.location.reload();
		}
	}



/**envio de datos para actualizar */

	async submitUpdate(params?) {
		this.swalDarkMode();
		if (this.formUser.valid) {
			let date: string;
			let tempDate: string;
			this.formUser.get('domains').enable()
			this.formUser.get('mail').enable()			
			let tempUsers = this.formUser.value;
			tempUsers['mail'] = tempUsers['mail'] + '@' + tempUsers['domains']		
			date = this.setDateSave(tempUsers.expiration);
			tempDate = date;
			tempUsers['expiration'] = tempDate;
			if (params) {
				tempUsers['_id'] = params;
				await this._maintenanceService.updateUser(tempUsers).subscribe(res => {
					this.reload(params);
					this.getFindUser()
				})
				const loadingToast = this.alertMessage.fire({
					icon: 'success',
					title: 'Usuario Actualizado de manera Exitosa',
					toast: true,
					background: this.swalBackground,
					color:this.swalTextColor,				
					position: 'top-end',
					showConfirmButton: false,
					timer: 1500,
					timerProgressBar: true,
				});
			}			
			this.btnUser = false;
		}
		else {
			const loadingToast = this.alertMessage.fire({
				icon: 'warning',
				title: 'Verifique que todos lo campos esten ingresado de manera correcta',
				toast: false,
				background: this.swalBackground,
				color:this.swalTextColor,			
				position: 'center', 	
				showConfirmButton: false,
				timer: 1500,
				timerProgressBar: true,
			});
		}
	}

/**envio de datos para nuevo usuario */
	async submitNew() {
		this.swalDarkMode();
		if (this.formUser.valid) {
			let date: string;
			let tempDate: string;
			let tempUsers = this.formUser.value;
			tempUsers['mail'] = tempUsers['mail'] + '@' + tempUsers['domains']
			date = this.setDateSave(tempUsers.expiration);
			tempDate = date;
			tempUsers['expiration'] = tempDate;
			await this._maintenanceService.newUser(tempUsers).subscribe((response) => {
				this.getFindUser()
			}, (error) => {
				console.log(error)			
			}
			)
			const loadingToast = this.alertMessage.fire({
				icon: 'success',
				title: 'Usuario Guardado de manera Exitosa',
				toast: true,
				background: this.swalBackground,
				color:this.swalTextColor,			
				position: 'top-end', 	
				showConfirmButton: false,
				timer: 1500,
				timerProgressBar: true,
			});
			this.btnUser = false;
		}
		else {
			const loadingToast = this.alertMessage.fire({
				icon: 'warning',
				title: 'Verifique que todos lo campos esten ingresado de manera correcta',
				toast: false,
				position: 'center',
				background: this.swalBackground,
				color:this.swalTextColor,			
				showConfirmButton: false,
				timer: 1500,
				timerProgressBar: true,
			});
		}
	}
}
