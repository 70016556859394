import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, inject, effect, ViewChild, } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@services/core/user.service';
import { BudgetGenerateService } from '@services/budget-generate.service';
import { Entity } from '../../budget-generate.interface';
import Swal from 'sweetalert2'
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { LOCALE_ID } from '@angular/core';
import { NumberFormatDirective } from 'app/directives/number-format.directive';
import { EmployeeModel } from 'app/model/employee.model';
import { SettingFieldSalaryComponent } from './setting-field/setting-field-salary.component';
import { Parser } from 'expr-eval';


@Component({
  selector: 'app-tabulation-salary',
  standalone: true,
  imports: [DecimalPipe, NgSelectModule, FormsModule, NumberFormatDirective, SettingFieldSalaryComponent, NgbModule, CommonModule],
  providers: [NgbModal, { provide: LOCALE_ID, useValue: 'es-ES' }],
  templateUrl: './tabulation-salary.component.html',
  styleUrl: './tabulation-salary.component.css'
})
export class TabulationSalaryComponent {
  @ViewChild('modal_account', { static: true }) modalAccount: any;
  @ViewChild('modal_setting', { static: true }) modalSetting: any;
  private modalRef: NgbModalRef;
  private _identityService = inject(UserService);
  public dataDepartament;
  public ngSelectedOld: string;
  public inputOld;
  public dataListDiverseDependent;
  public dataListDiverseIndependent;
  public subtotalDependent;
  public subtotalIndependent;
  private entitySelected: Entity;
  public codeEntity;
  public txtFindDependent;
  public txtFindIndependent;
  public salaryService = inject(BudgetGenerateService)
  public dependentEmployee = [];
  public dependentEmployeeTemp;
  public independentEmployee = []
  public independentEmployeeTemp;
  public dependentAccountPlanVersion;
  public independentAccountPlanVersion;
  public filter: EmployeeModel = new EmployeeModel();
  public navTab = 'dependientes'
  public rows =Array.from({length: 15}, (_, i) => i + 1);
  public cols =Array.from({length: 5}, (_, i) => i + 1);
  public isLoading = true;
  public activeDependent=false
  public activeIndependent=false
  public title='Presupuesto'




  constructor(private modalService: NgbModal

  ) {


    effect(() => {
      this.entitySelected = this._identityService.sharingEntity();
      if (this.entitySelected && Object.keys(this.entitySelected).length > 0) {
        this.filter.year = 2024;
        this.filter.version = 1;
        this.getDepartement()
        this.getSalariesDependent()
        this.getSalariesIndependent()
        this.getAccountPlanVersion()
        this.getSalariesSubtotalDependent()
        this.getSalariesSubtotalIndependent()
        this.ngOnInit()
      }

    })

  }


  ngOnInit() {
    /**aqui podemos recibir los valores desde la tabla budget_version */
    this.filter.year = 2024;
    this.filter.version = 1;
  }

  openModalAccount() {
    this.open(this.modalAccount, { size: 'xl' })
  }

  openModalSetting() {
    this.open(this.modalSetting)
  }

  /*abre los modales */
  open(content, size?) {
    this.modalRef = this.modalService.open(content, size);
  }

  /*cierra los modales */
  close() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  /**Selecciona todo el texto del input */
  selectAll(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.select();
  }


  where() {
    let queryParams = '';
    if (this.filter) {
      queryParams = Object.keys(this.filter)
        .map(key => `${this.filter[key as keyof EmployeeModel]}`)
        .join('/');
    }
    return queryParams ? `${queryParams}` : '';
  }



  querySubtotal(employeeType) {
    if (employeeType === 1) {

      this.getSalariesSubtotalDependent()
    }
    else {
      this.getSalariesSubtotalIndependent()
    }
  }


  notification(title) {
    const Toast = Swal.mixin({
      icon: 'success',
      title: title,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2500,
      timerProgressBar: true,
    });
    Toast.fire();
  }


  updateValueSalary(employee, input, newValue, employeeType) {
    const currentValue = employee[input];
    if (newValue && currentValue?.toString() != newValue) {
      employee[input] = newValue;
      const account = this.setEmployeeAccount(employee, input);
      account.value = newValue;
      this.salaryService.updateSalaries(employee).subscribe(res => {
        this.updateFmrZero(employee, input, newValue, employeeType)
        this.querySubtotal(employeeType)
        this.notification('Actualizado');
      })
    }
    else {
      const account = this.setEmployeeAccount(employee, input);
      account.value = currentValue


    }
  }


  updateValueSalaryNgSelect(employee, input, newValue) {
    employee[input] = newValue
    this.salaryService.updateSalaries(employee).subscribe(res => {
    })

  }


  updateValueAccounts(employee, account_code, value, employeeType) {
    value = value.replace(/\./g, '').replace('.', ',');
    if (this.inputOld && value != this.inputOld) {
      const account = this.setEmployeeAccount(employee, account_code);
      account.value = value
      this.salaryService.updateAccount({ id: account?.id, value: value }).subscribe(res => {
        this.querySubtotal(employeeType)
        this.notification('Actualizado');
      })

    }
    this.inputOld = value;
  }


  updateValueAccountsCheck(employee, account_code, value, employeeType) {
    const account = this.setEmployeeAccount(employee, account_code);
    this.salaryService.updateAccount({ id: account.id, value: value }).subscribe(res => {
      const account = employee.accounts.find(acc => acc.account_code == account_code);
      account.value = value;
      this.notification('Actualizado');
    })
  }


  whereUpdateAccount(employee, account_code) {
    return {
      account_code: account_code,
      employee_code: employee['employee_code'],
      year: employee['year'],
      version: employee['version']
    }

  }

  // Divide la fórmula en sus componentes, incluyendo códigos, variables, números y operadores.
  splitFormula(formula) {
    try {
      const regex = /(\{\d+\}|\{\w+\}|\d+(?:\.\d+)?|\+|\-|\*|\/|\(|\))/g;
      return formula.match(regex).filter(Boolean);
    } catch {
      return [];
    }
  }

  // Busca el valor de una cuenta específica dentro del array de cuentas de un empleado.  
  findEmployeeAccountValue(employeeAccount, code) {
    let value = 0;
    if (Array.isArray(employeeAccount)) {
      let account = employeeAccount.find(acc => acc.account_code == code);
      if (account) {
        value = account.value;
      }
    }
    return value
  }

  // Reemplaza los códigos en la fórmula por sus valores correspondientes del array de cuentas del empleado.
  replaceCodesWithValues(formulaArray, employeeAccount) {
    return formulaArray.map(item => {
      const match = item.match(/^\{([\w-]+)\}$/);
      let value = item
      if (match) {// Si el item es un código en el formato {código}, busca el valor      
        const code = match[1]; // Extrae el código numérico        
        value = this.findEmployeeAccountValue(employeeAccount, code);
      }
      return value;
    });
  }

  // Convierte el array de la fórmula en una cadena y la evalúa para obtener el resultado final.
  executeCalculation(formulaArray) {
    const parser = new Parser();
    const formulaString = formulaArray.join(' ');
    try {
      let result = Math.round(parser.evaluate(formulaString))
      return result;
    } catch (error) {
      return null;
    }
  }

  /** */
  updateValueCalculated(employee, account_code, valueNew, employeeType) {
    let valueOld = this.findEmployeeAccountValue(employee.accounts, account_code);
    if (valueNew!=undefined && valueNew != valueOld) {
      const account = this.setEmployeeAccount(employee, account_code);
      account.value = valueNew
      this.salaryService.updateAccount({ id: account.id, value: valueNew }).subscribe(res => {
        this.querySubtotal(employeeType)
      })
    }
  }


  setEmployeeAccount(employee, accountCode) {
    return employee.accounts?.find(acc => acc.account_code == accountCode);
  }



  //realiza un cálculo si se proporciona una fórmula.
  calculation(employee, accountCode, account, employeeType) {
    if (account.fmr_applied && this.findEmployeeAccountValue(employee.accounts, 'fmr_employee') != 0 || !account.fmr_applied && this.findEmployeeAccountValue(employee.accounts, 'fmr_employee') >= 0) {
      const formulaArray = this.splitFormula(account.calculation);
      const formulaWithValues = this.replaceCodesWithValues(formulaArray, employee.accounts);
      let resultCalculation = this.executeCalculation(formulaWithValues);
      this.updateValueCalculated(employee, accountCode, resultCalculation, employeeType)
    }
  }


  /** dejamos solo las cuentas que depende de fmr*/
  accountDeleteConst(employee) {
    const employeeCopy = [...
      employee.accounts.filter(account => account.fmr_applied === true)]
    return employeeCopy
  }


  /**actualiza todas las cuentas que depende d fmr */
  updateFmrZero(employee, accountCode, value, employeeType) {
    const account = this.accountDeleteConst(employee)
    if (accountCode == 'fmr_employee' && value == 0) {
      account.map(a => {
        const itemAccount = this.setEmployeeAccount(employee, a.account_code);
        itemAccount.value = 0;
        this.salaryService.updateAccount({ id: a.id, value: 0 }).subscribe(res => {
          this.querySubtotal(employeeType)
        })
      })
    }
  }

  // Obtiene el valor de una cuenta
  getAccountValue(employee, accountCode, account, employeeType) {
    if (account?.calculation) {
      this.calculation(employee, accountCode, account, employeeType)
    }
    return this.findEmployeeAccountValue(employee.accounts, accountCode);
  }


  // Obtiene el valor de una cuenta
  getSubtotalDependent(accountCode) {
    let account = this.subtotalDependent?.find(acc => acc.account_code == accountCode);
    return account ? account.sub_total : null;

  }

  /**trae los datos de los dependientes */
  getSalariesDependent() {
    this.filter.type_id = 1;
    let where = this.where();
    this.salaryService.getSalaries(where).subscribe(res => {
      this.dependentEmployee = res;
      this.dependentEmployeeTemp = JSON.parse(JSON.stringify(res));
      this.dataListDiverseDependent = this.ngSelectDiverse(res)
      this.isLoading =false;
      Swal.close()
    })
  }


  /**trae los datos de los independientes */
  getSalariesIndependent() {
    this.filter.type_id = 2;
    let where = this.where();
    this.salaryService.getSalaries(where).subscribe(res => {
      this.independentEmployee = res;
      this.independentEmployeeTemp = res;
      this.dataListDiverseIndependent = this.ngSelectDiverse(res)
      Swal.close()
    })
  }

  /**trae los departamentos */
  getDepartement() {
    this.salaryService.getDepartament().subscribe(res => {
      this.dataDepartament = this.ngSelectData(res);
    })
  }


  /**trae los datos de los subtotal de independientes */
  getSalariesSubtotalIndependent() {
    this.filter.type_id = 2;
    let where = this.where();
    this.salaryService.getSubtotal(where).subscribe(res => {
      this.subtotalIndependent = res
    })
  }

  /**trae los datos de los subtotal de dependientes */
  getSalariesSubtotalDependent() {
    this.filter.type_id = 1;
    let where = this.where();
    this.salaryService.getSubtotal(where).subscribe(res => {
      this.subtotalDependent = res
    })
  }



  /*al momento de dar un click almacena el valor anterior del input en otra funcion se compara el valor nuevo con el anterior */
  toggleInput(value) {
    this.inputOld = value.replace(/\./g, '').replace('.', ',');
  }


  /**Mapea los datos en formato valido para ngSelect */
  ngSelectData(data) {
    let newData = data.map((d) => {
      return {
        code: d.code.toString(),
        codeName: d.code + ' - ' + d.name.slice(0, 30),
        name: d.name
      }
    });
    return newData
  }


  /*mapea los datos del ng-select */
  ngSelectDiverse(diverse) {
    let newData = diverse.map((d) => {
      return {
        diverse: d.employee_code,
        nameDiverse: d.employee_code + ' - ' + d.names,
        name: d.names
      }
    });
    return newData
  }


  /**al momento de cerrar el ng select de busqueda busca actualiza la tabla de diversos  */

  changeNgSelectDependent() {
    if (!this.txtFindDependent) {
      this.dependentEmployee = this.dependentEmployeeTemp;
    } else {
      this.dependentEmployee = this.dependentEmployee.filter(d => d.employee_code == this.txtFindDependent);
    }

  }


  /**al momento de cerrar el ng select de busqueda busca actualiza la tabla de diversos  */
  changeNgSelectIndependent() {
    if (!this.txtFindIndependent) {
      this.independentEmployee = this.independentEmployeeTemp;
    } else {
      this.independentEmployee = this.independentEmployee.filter(d => d.employee_code == this.txtFindIndependent);
    }

  }


  /**trae el plan de cuentas */
  getAccountPlanVersion() {
 
    delete this.filter.type_id;
    this.salaryService.getAccountPlanVersion(this.where()).subscribe(res => {
      this.dependentAccountPlanVersion = res.filter(r => r.employee_type === 1)
      this.independentAccountPlanVersion = res.filter(r => r.employee_type === 2)
      this.cols=Array.from({length:this.dependentAccountPlanVersion.length}, (_, i) => i + 1);
    })
  }


}
