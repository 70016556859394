@if(visible){
	<div  [ngStyle]="{'top.px': y, 'left.px': x}" class="context-menu">
		<ul>
			@for(action of actions;track action.label){
			<li (click)="action.onClick(); hide()">
				@if(action.icon){
				<i [class]="action.icon"></i>} &nbsp;
				{{ action.label }}
			</li>
			}
		</ul>
	  </div>
}