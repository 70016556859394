<div *ngIf="isLogged">
  <!-- BEGIN #app -->
  <div id="app" class="app"[ngClass]="{
    'has-scroll' : appHasScroll,
    'app-sidebar-minified': appSettings.appSidebarMinified,
		'app-sidebar-fixed': !appSettings.appSidebarNone && appSettings.appSidebarFixed,
		'app-header-fixed': !appSettings.appHeaderNone && appSettings.appHeaderFixed,
    'app-without-sidebar': appSettings.appSidebarNone || appSettings.appEmpty,
    'app-without-header': appSettings.appHeaderNone || appSettings.appEmpty,
    'app-with-end-sidebar': appSettings.appSidebarEnd,
    'app-with-two-sidebar': appSettings.appSidebarTwo,
    'app-with-wide-sidebar': appSettings.appSidebarWide,
    'app-sidebar-end-toggled': appSettings.appSidebarEndToggled,
    'app-sidebar-mobile-toggled': appSettings.appSidebarMobileToggled,
    'app-content-full-height': appSettings.appContentFullHeight,
    'app-with-top-menu': appSettings.appTopMenu,
    'app-sidebar-end-mobile-toggled': appSettings.appSidebarEndMobileToggled,
    'app-gradient-enabled': appSettings.appGradientEnabled,
    'p-0': appSettings.appEmpty
  }">
  
  
    <header 
      (appSidebarMobileToggled)="onAppSidebarMobileToggled($event)" 
      (appSidebarEndToggled)="onAppSidebarEndToggled($event)" 
      (appSidebarEndMobileToggled)="onAppSidebarEndMobileToggled($event)" 
      [appSidebarTwo]="appSettings.appSidebarTwo" 
      *ngIf="!appSettings.appEmpty && !appSettings.appHeaderNone">
    </header>
  
    <sidebar 
      (appSidebarMinifiedToggled)="onAppSidebarMinifiedToggled($event)" 
      (appSidebarMobileToggled)="onAppSidebarMobileToggled($event)" 
      [appSidebarMinified]="appSettings.appSidebarMinified"
      *ngIf="!appSettings.appSidebarNone && !appSettings.appEmpty">
    </sidebar>

   
  
    <div id="content" class="app-content" [ngClass]="appSettings.appContentClass + ' ' + (appSettings.appEmpty ? 'p-0 m-0' : '')">
      <router-outlet></router-outlet>
    </div>

    <theme-panel
  	(appDarkModeChanged)="onAppDarkModeChanged($event)"
  	(appThemeChanged)="onAppThemeChanged($event)"
  	*ngIf="!appSettings.appThemePanelNone"
  	></theme-panel>
    
  </div>
  <!-- END #app -->
</div>