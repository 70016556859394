<!-- presupuesto -->

<h1 class="page-header"><i class="fas fa-chart-line"></i> Presupuesto<small> Generar presupuesto {{year}}</small></h1>


<!-- begin panel -->
<ul class="nav nav-tabs">
    <li class="nav-item">
        <a href="#variables" data-bs-toggle="tab" (click)="navTab='variables'" class="nav-link ">Variables</a>
    </li>
    <li class="nav-item">
        <a href="#cuentas" data-bs-toggle="tab" (click)="navTab='cuentas'" class="nav-link ">Cuentas</a>
    </li>
    <li class="nav-item">
        <a href="#resumen" data-bs-toggle="tab" (click)="navTab='resumen'" class="nav-link active">Resumen</a>
    </li>
</ul>
<div class="tab-content panel p-4 rounded-0 rounded-bottom">
    <!--pestaña 1-->
    <div class="tab-pane fade" [ngClass]="{ 'show active ': navTab === 'variables' }" id="variables">
        @if(navTab==='variables'){
            <app-tab-variable></app-tab-variable>
        }        
    </div>
    <!--pestaña 2-->
    <div class="tab-pane fade" [ngClass]="{ 'show active ': navTab === 'cuentas' }" id="cuentas">
        
        @if(navTab==='cuentas'){
            <div class="mt-2">
                <div class="btn-group flex-wrap">
                    <button (click)="tab='tabSueldo'" [ngClass]="{ 'active ': tab === 'tabSueldo' }" class="btn btn-white">Tabulación Sueldos</button>
                    <button (click)="tab='tabViajes'" [ngClass]="{ 'active ': tab === 'tabViajes' }" class="btn btn-white">Viajes</button>
                    <button (click)="tab='tabSueldos441'" [ngClass]="{ 'active ': tab === 'tabSueldos441' }" class="btn btn-white">Sueldos(411)</button>
                    <button (click)="tab='tabGastos412'" [ngClass]="{ 'active ': tab === 'tabGastos412' }" class="btn btn-white">Gastos Operativos(412)</button>
                    <button (click)="tab='tabEventos414'" [ngClass]="{ 'active ': tab === 'tabEventos414' }" class="btn btn-white">Gastos, Reuniones y Eventos(414)</button>
                    <button (click)="tab='tabOtorgamientos419'" [ngClass]="{ 'active ': tab === 'tabOtorgamientos419' }" class="btn btn-white">Ortogamientos(419)</button>
                    <button (click)="tab='tabIngresos'" [ngClass]="{ 'active ': tab === 'tabIngresos' }" class="btn btn-white">Ingresos</button>
                </div>
            </div>
            <br>
            @if(tab==='tabSueldo'){
                <app-tabulation-salary></app-tabulation-salary>
            }
            @if(tab==='tabViajes'){
                <app-travel></app-travel>
            }
            
        }
    </div>
    <!--pestaña 3-->
    <div class="tab-pane fade" [ngClass]="{ 'show active ': navTab === 'resumen' }" id="resumen">
        @if(navTab==='resumen'){
            <app-setting-field-salary></app-setting-field-salary>
        }
    </div>

</div>
<!-- end pane -->