import { inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '@services/core/Global';
import { StorageService } from '@services/core/storage.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  public userData = signal(null)
  public userPhoto = signal<string | null>(null)
  public sharingIdentity = signal({})
  public sharingEntity = signal({})
  public sharingEntities = signal<[]>([])
  public sharingLanguage = signal({})
  public sharingRole = signal({})
  public sharingMenu = signal<[]>([])
  public sharingMenuPath = signal({})
  public token;
  public url: string;
  storageService = inject(StorageService)

  constructor(
    private _http: HttpClient

  ) { 
     this.url = Global.url_app;
     this.token = this.storageService.getJsonValue("token") 
    }


  processUserData(userData?: any) {
    if(userData) this.userData.set(userData)
  }

  processUserPhoto(arrayBuffer?: ArrayBuffer) {
    const url = this.arrayBufferToUrl(arrayBuffer);
    this.userPhoto.set(url)
  }

  private arrayBufferToUrl(arrayBuffer: ArrayBuffer): string {
    const array = new Uint8Array(arrayBuffer);
    const blob = new Blob([array], { type: "image/jpeg" });
    return URL.createObjectURL(blob);
  }

  changeEntity(params): Observable<any> {
    let headers = new HttpHeaders()
     .set('Content-Type', 'application/json')
     .set('Authorization', this.token);
     let result=this._http.put(this.url+'user/',params ,{ headers: headers });
   return result
 }

}
