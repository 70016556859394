<h1 class="page-header"><i class="fas fa-chart-line"></i> Presupuesto<small> Generar presupuesto {{'year'}}</small></h1>

<div class="panel " data-sortable-id="ui-widget-16">
    <div class="panel-heading ">
    </div>
    <div class="panel-body p-4">
        <div class="row">
            <!--diverse-->
            <div class="row mb-3 mt-2">
                <div class="col-6 col-md-5">
                    <ng-select [items]="dataListDiverse" [selectableGroup]="true" [hideSelected]="true"
                        bindLabel="nameDiverse" [closeOnSelect]="true" [dropdownPosition]="'bottom'" bindValue="diverse"
                        placeholder="Buscar por diverso, nombre..." [(ngModel)]="txtFind"
                        (change)="onCloseNgSelectFind()" tabindex="1">
                    </ng-select>
                </div>
                <div class="col-6 col-md-7 d-flex justify-content-end">
                    <button class="btn btn-success text-white btn-sm " (click)="openModalDiverse()">Agregar
                        Diverso</button>
                </div>
            </div>

            <div class=" mt-4">
                <div class="table-data">
                    <table class="table table-striped  table-sm ">
                        <thead>
                            <tr>
                                <th>N° </th>
                                <th>Diverso</th>
                                <th>Nombre Funcionario</th>
                                <th>Tipo de Empleado</th>
                                <th>Estado</th>
                                <th><span class="d-flex justify-content-center"><i class="fa fa-cogs"></i></span></th>
                            </tr>
                        </thead>
                        @if(dataDiverse?.length>0){
                        <tbody>
                            @for(d of dataDiverse;track $index; let idx = $index){
                            <tr>
                                <td>{{idx+1}}</td>
                                <td>{{d.code}}</td>
                                <td>{{d.names}}</td>
                                <td >{{findEmployeetype(d.type)}}</td>
                                <td><span
                                        class="badge border fs-12px pb-5px pt-5px px-2 rounded d-inline-flex align-items-center"
                                        [ngClass]="{ 'border-success text-success': d.is_active, 'border-warning text-warning': !d.is_active  }">
                                        <i
                                            class=" fas fa-circle fs-9px fa-fw me-5px "></i>{{d.is_active?'Activo':'Inactivo'}}</span>
                                </td>
                                <td>
                                    <span class="d-flex justify-content-center">
                                        @if(d.is_active){
                                        <button type="button" class="btn btn-outline-primary btn-xs "
                                            (click)="setFormDiverse(d)"><i class="fa fa-pencil-alt"></i></button>&nbsp;
                                        }
                                        @if(d.is_active){
                                        <button type="button" class="btn btn-outline-warning btn-xs" placement="right"
                                            ngbTooltip="Excluir" (click)="UpdateDiverseActive(d,false)"><i
                                                class="fa fa-user-slash"></i></button>
                                        }
                                        @if(!d.is_active){
                                        <button type="button" class="btn btn-outline-success btn-xs" placement="left"
                                            ngbTooltip="Activar" (click)="UpdateDiverseActive(d,true)"><i
                                                class="fas fa-check"></i></button>&nbsp;
                                        <button type="button" class="btn btn-outline-danger btn-xs" placement="right"
                                            ngbTooltip="Eliminar" (click)="deleteDiverse(d)"><i
                                                class="fas fa-trash"></i></button>
                                        }
                                    </span>
                                </td>

                            </tr>
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colspan="6">{{dataDiverse?.length}} en Total</th>
                            </tr>
                        </tfoot>
                        }
                    </table>
                    @if(dataDiverse?.length===0){
                    <div class="m-l-10 mb-2">
                        <em>Sin registros</em>
                    </div>
                    }
                </div>
            </div>


        </div>
    </div>



    <!--modal diverse-->

    <ng-template #modal_diverse let-c="close" let-d="dismiss" let-modal>

        <div class="modal-header">
            <h5 class="modal-title">{{modalHeaderDiverse}}</h5>
            <span (click)="d('Cross click');isNewDiverse=false;diverseFinded=false" type="button"
                class="btn-close bt-xs" data-dismiss="modal" tabindex="8">

            </span>
        </div>
        <div class="modal-body">
            <div class="panel-body panel-form">
                <form [formGroup]="formDiverse">
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label">N° Diverso</label>
                        <div class="col-md-8">
                            @if(diverseFinded==true || isNewDiverse==true){
                            <ng-select #ngSelectAssinet [items]="dataNgSelectAssinet" [selectableGroup]="true"
                                [hideSelected]="true" bindLabel="nameDiverse" [closeOnSelect]="true"
                                [dropdownPosition]="'bottom'" bindValue="diverse" placeholder="Seleccionar diverso"
                                formControlName="code" name="code" (close)="onCloseNgSelectAssinet()" tabindex="1">
                            </ng-select>
                            }
                            @else{


                            {{diverseCopy}}&nbsp;&nbsp;<button type="button" class="btn btn-outline-primary btn-sm"><i
                                    class="fa-solid fa-pen-to-square btn-primary" (click)="editDiverse()"></i></button>

                            }
                            @if(formDiverse.get('code')?.errors?.required && formDiverse.get('code').touched){
                            <div><small class="text-danger">Diverso es requerido.</small></div>
                            }
                        </div>
                    </div>&nbsp;
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label">Nombre Funcionario</label>
                        <div class="col-md-8">
                            <input type="text" #nameInput
                                [class.is-invalid]="formDiverse.get('names').invalid&&formDiverse.get('names').touched"
                                (click)="formDiverse.get('names').markAsUntouched();"
                                (focus)="formDiverse.get('names').markAsUntouched();" formControlName="names"
                                name="names" class="form-control" tabindex="2" />
                            @if(formDiverse.get('names')?.errors?.required && formDiverse.get('names').touched){
                            <div><small class="text-danger">Nombre de funcionario es requerido.</small></div>
                            }
                        </div>
                    </div>&nbsp;
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label">Tipo Diverso</label>
                        <div class="col-md-8">
                            <select
                                [class.is-invalid]="formDiverse.get('type').invalid&&formDiverse.get('type').touched"
                                (click)="formDiverse.get('type').markAsUntouched();"
                                (focus)="formDiverse.get('type').markAsUntouched();" class="form-control form-select"
                                formControlName="type" name="type" tabindex="3">
                                <option disabled selected>Seleccione un tipo de diverso</option>
                                @for(e of dataEmployeeType;track e){
                                <option [value]="e.id">{{e.name}}</option>
                                }
                            </select>
                            @if(formDiverse.get('type')?.errors?.required && formDiverse.get('type').touched){
                            <div><small class="text-danger">Tipo de diverso es requerido.</small></div>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-sm"
                (click)="d('Cross click');isNewDiverse=false;diverseFinded=false"><i class="fas fa-times"></i>&nbsp;
                Cerrar</button>
            @if(modalHeaderDiverse==='Nuevo Diverso'){
            <button type="button" class="btn btn-primary btn-sm"
                (click)="submitDiverseNew();isNewDiverse=false;diverseFinded=false"
                [disabled]="formDiverse.pristine&&!formDiverse.valid||!formDiverse.valid" tabindex="4"><i
                    class="fas fa-save"></i>&nbsp;Guardar</button>
            }
            @if(modalHeaderDiverse==='Modificar Diverso'){
            <button type="button" class="btn btn-primary btn-sm"
                (click)="submitDiverseUpdate();isNewDiverse=false;diverseFinded=false"
                [disabled]="formDiverse.pristine||!formDiverse.valid" tabindex="4"><i
                    class="fas fa-save"></i>&nbsp;Guardar</button>
            }
        </div>
    </ng-template>
    <!--end diverse-->