<!-- BEGIN #header -->
<div id="header" class="app-header" [attr.data-bs-theme]="this.appSettings.appHeaderInverse ? 'dark' : ''">
	<!-- BEGIN navbar-header -->
	<div class="navbar-header">
		<a href="/" class="navbar-header"><img class="mt-0" [src]="logo"></a>
		<button type="button" class="navbar-mobile-toggler" (click)="toggleAppSidebarMobile()">
			<span class="icon-bar"></span>
			<span class="icon-bar"></span>
			<span class="icon-bar"></span>
		</button>
	</div>

	<div class="navbar-nav">
		<div class="navbar-item dropdown ">
			<a href="javascript:;" data-bs-toggle="dropdown" class="navbar-link dropdown-toggle icon">
				<i class="fa fa-university"></i>
				<span class="badge">{{entities?.length}}</span>
			</a>
			<div class="dropdown-menu media-list dropdown-menu-end entity-dropdown">
				<div class=" sticky-header">
					<div class="dropdown-header">ENTIDADES ({{entities?.length}})</div>
					@if( entitiesTemp?.length >= 5){
					<div class="m-2 width-full">
						<input class="form-control" type="text" #entityFilter placeholder="Busca tu entidad aquí..."
							(keyup)="filtrarEntidades(entityFilter.value)" />
					</div>
					}
				</div>

				@if(entities?.length > 0){
				@for(entity of entities; track entity._id){
				<a href="javascript:;" (click)="cambiaentidad(entity)" class="dropdown-item media">
					<div class="media-left">
						<i class="{{entity.icon}} media-object text-black bg-gray-500"></i>
					</div>
					<div class="media-body">
						<h6 class="media-heading">{{entity.code}} {{entity.group}}</h6>
						<p>{{entity.name}}</p>
						<div class="text-muted fs-11">{{entity.campus}}</div>
					</div>
				</a>
				}
				}
				@else{
				<a href="javascript:;" class="dropdown-item media">
					<div class="media-left">
						<i class="text-danger far fa-3x fa-fw m-r-10 fa-times-circle"></i>
					</div>
					<div class="media-body ">
						<h6 class="media-heading text-wrap">La entidad que esta buscando no se encontró o no tiene
							acceso a ella.
						</h6>
					</div>
				</a>
				}

			</div>
		</div>
		<div class="navbar-item navbar-user dropdown">
			<a href="javascript:;" class="navbar-link dropdown-toggle d-flex align-items-center"
				data-bs-toggle="dropdown">
				@if(profilePhotoURL) {
				<img [src]="profilePhotoURL" alt="profilePhoto" />
				}

				<span>
					<span class="d-none d-md-inline">{{userData?.displayName}}</span>
					<b class="caret"></b>
				</span>
			</a>
			<div class="dropdown-menu dropdown-menu-end me-1">
				<a href="https://myworkaccount.microsoft.com/" class="dropdown-item">Mi Cuenta</a>
				<a href="javascript:;" (click)="logout()" class="dropdown-item">Cerrar Sesión</a>
			</div>
		</div>
	</div>
</div>