<!-- presupuesto -->

<h1 class="page-header"><i class="fas fa-chart-line"></i>{{title}}<small> {{navTab=='dependientes'&&activeDependent?'Dependientes':''+navTab=='independientes'&&activeIndependent?'independientes':''+!activeIndependent&&!activeDependent?'2025 - 1':''}} </small></h1>
@if(activeDependent){
    <div class="panel p-4">
        <app-setting-field-salary [employeeType]="1" (activeChange)="activeDependent=$event;title='Presupuesto'"></app-setting-field-salary>
    </div>
} 

@if(activeIndependent){
    <div class="panel p-4">
        <app-setting-field-salary [employeeType]="2" (activeChange)="activeIndependent=$event;title='Presupuesto'"></app-setting-field-salary>
    </div>
} 

@if(!activeDependent&&!activeIndependent){
<!-- begin panel -->
<ul class="nav nav-tabs">
    <li class="nav-item">
        <a href="#dependientes" data-bs-toggle="tab" (click)="navTab='dependientes'" class="nav-link" [class]="navTab=='dependientes' ? 'active' : ''">Dependientes</a>
    </li>
    <li class="nav-item">
        <a href="#independientes" data-bs-toggle="tab" (click)="navTab='independientes'" class="nav-link  " [class]="navTab=='independientes' ? 'active' : ''">Independientes</a>
    </li>
</ul>
<div class="tab-content panel p-4 rounded-0 rounded-bottom">
    <!--pestaña 1-->
      <div class="tab-pane fade" [ngClass]="{ 'show active ': navTab === 'dependientes' }" id="dependientes">
             
    
        <div class="row mb-3 mt-2">
            <div class="col-6 col-md-4">
                <ng-select [items]="dataListDiverseDependent" [selectableGroup]="true" [multiple]="true"  [hideSelected]="true" bindLabel="nameDiverse"
                    [closeOnSelect]="true" [dropdownPosition]="'bottom'" bindValue="diverse" (change)="changeNgSelectDependent()" 
                    placeholder="Buscar por diverso, nombre..." [(ngModel)]="txtFindDependent" tabindex="1">
                </ng-select>
            </div>
            <div class="col-6 col-md-8 d-flex justify-content-end">
                 <a href="javascript:;" class="btn btn-success text-white" (click)="activeDependent=true;title='Configuracion de Cuentas'"><i
                        class="fas fa-cog"></i> Configurar Cuentas</a>&nbsp;
            </div>
        </div>
        <div class="table-responsive table-data">
            <table class="table table-sm ">
                <thead>
                    <tr>
                        <th class="sticky-column text-nowrap ">N° Diverso</th>
                        <th class="sticky-column-2 text-nowrap  ">Nombre Empleado</th>
                        <th class="text-nowrap ">Departamento</th>
                        <th class="text-nowrap">% FMR</th>
                        @for(account of dependentAccountPlanVersion; track account.id){
                        @if(account.is_visible){
                        <th >                    
                            <div class="text-center " ngbTooltip={{account.hint}}>
                                <span class="text-nowrap">{{account.display_name.slice(0,20)}}</span>
                                <br>
                             @if(account.account_type!=2){
                                <span >({{account.account_code}})</span>
                             }
                            </div> 
                        </th>
                        }
                        }
                        <th class="text-nowrap">Totales</th>
                    </tr>
                </thead>
                <tbody>
                      <!--muestra una tabla cargando mientras se estan cargado los datos-->
                      @if(isLoading){
                        @for( i of rows ;track $index){
                        <tr  class="tr-custom">
                            @for( i of cols ;track $index){
                            <td> <p class="p-skeleton m-1" style="width: 100%; height: 12px;"></p></td>
                            }
                        </tr>
                        }
                    }
                    @for(employee of dependentEmployee; track employee.id){
                    <tr>
                        <td class="text-nowrap sticky-column align-middle bg-light text-center">{{employee.employee_code}}
                        </td>
                        <td class="text-nowrap sticky-column-2 align-middle bg-light">{{employee.names}}
                            {{employee.surname}} {{employee.lastname}}</td>
                        <td class="text-nowrap">
                            <ng-select [items]="dataDepartament" [hideSelected]="true"
                                bindLabel="codeName" bindValue="code" 
                                [(ngModel)]="employee.departament_code"
                                [clearable]="false"
                                (change)="updateValueSalaryNgSelect(employee, 'departament_code', employee.departament_code)"
                                    >
                            </ng-select>
                        </td>
                        <td class="text-nowrap ">
                            <input type="text" class="form-control form-control input-sm" #fmr [value]="employee.fmr_employee"  
                            (keyup.enter)="updateValueSalary(employee, 'fmr_employee', fmr.value,1)"
                            (blur)="updateValueSalary(employee, 'fmr_employee', fmr.value,1)">
                        </td>
                        @for(account of dependentAccountPlanVersion; track account.id){
                        @if(account.is_visible){
                        @if(findEmployeeAccountValue(employee.accounts,'fmr_employee')==0&&!account.block||!account.block&&!account.missionary_block||findEmployeeAccountValue(employee.accounts,'fmr_employee')>0&&!account.missionary_block){
                        <td class="align-middle text-nowrap text-center">
                            @if(account.type_input=='text'){
                            <input class="form-control form-control input-md" (click)="toggleInput(accountInput.value);selectAll($event)" (focus)="toggleInput(accountInput.value)"
                                #accountInput type="text"
                                [value]="getAccountValue(employee, account.account_code,account,1) | number: '1.0-0'"
                                (blur)="updateValueAccounts(employee, account.account_code, accountInput.value,1)"                   
                                (keyup.enter)="updateValueAccounts(employee, account.account_code, accountInput.value,1)"
                                appNumberFormat>
                            }@else{
                                <input type="checkbox" class="form-check-input" #apv 
                                (change)=" updateValueAccountsCheck(employee, account.account_code, apv.checked,1)" [checked]="getAccountValue(employee, account.account_code,account.calculation,1) " />
                            }
                        </td>
                        }@else {
                        <td class="align-middle text-nowrap text-center bg-light">
                            <span>{{getAccountValue(employee, account.account_code,account) | number: '1.0-0'}}</span>
                        </td>
                        }
        
                        }
                    }
                        <td class="text-nowrap bg-light">$ {{employee.totales | number:'1.0-0'}}</td>
                    </tr>
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <th class="sticky-column"></th>
                        <th class="sticky-column-2">Sub Totales</th>
                        <th class="text-center">-</th>
                        <th class="text-center">-</th>
                        @for(account of dependentAccountPlanVersion; track i;let i= $index){
                            @if(account.is_visible){
                            <th class="align-middle text-nowrap">
                              <span>{{ getSubtotalDependent(account.account_code)| number: '1.0-0'}}</span>
                            </th>
                            }
        
                        }
                        <th class="align-middle text-nowrap">
                        </th>
        
                    </tr>
                </tfoot>
            </table>
        </div>
    
    </div>
    
    <!--pestaña 2-->
    <div class="tab-pane fade" [ngClass]="{ 'show active ': navTab === 'independientes' }" id="independientes">      
        
        <div class="row mb-3 mt-2">
            <div class="col-6 col-md-4">
                <ng-select [items]="dataListDiverseIndependent" [selectableGroup]="true" [hideSelected]="true" bindLabel="nameDiverse"
                    [closeOnSelect]="true" [dropdownPosition]="'bottom'" bindValue="diverse" (change)="changeNgSelectIndependent()" 
                    placeholder="Buscar por diverso, nombre..." [(ngModel)]="txtFindIndependent" tabindex="1">
                </ng-select>
            </div>
            <div class="col-6 col-md-8 d-flex justify-content-end">
                    <a href="javascript:;" class="btn btn-success text-white" (click)="activeIndependent=true;title='Configuracion de Cuentas'"><i
                        class="fas fa-cog"></i> Configurar Sueldos</a>&nbsp;
            </div>
        </div>
        
        <div class="table-responsive table-data">
            <table class="table table-sm ">
                <thead>
                    <tr>
                        <th class="sticky-column text-nowrap ">N° Diverso</th>
                        <th class="sticky-column-2 text-nowrap  ">Nombre Empleado</th>
                        <th class="text-nowrap ">Departamento</th>
                        <th class="text-nowrap">% FMR</th>
                        @for(account of independentAccountPlanVersion; track account.id){
                        @if(account.is_visible){
                        <th >                    
                            <div class="text-center " ngbTooltip={{account.hint}}>
                                <span class="text-nowrap">{{account.display_name.slice(0,20)}}</span>
                                <br>
                             @if(account.account_type!=2){
                                <span >({{account.account_code}})</span>
                             }
                            </div> 
                        </th>
                        }
                        }
                        <th class="text-nowrap">Totales</th>
                    </tr>
                </thead>
                <tbody>
                    @for(employee of independentEmployee; track employee.id){
                    <tr>
                        <td class="text-nowrap sticky-column align-middle bg-light text-center">{{employee.employee_code}}
                        </td>
                        <td class="text-nowrap sticky-column-2 align-middle bg-light">{{employee.names}}
                            {{employee.surname}} {{employee.lastname}}</td>
                        <td class="text-nowrap">
                            <ng-select [items]="dataDepartament" [hideSelected]="true"
                                bindLabel="codeName" bindValue="code" 
                                [(ngModel)]="employee.departament_code"
                                [clearable]="false"
                                (change)="updateValueSalaryNgSelect(employee, 'departament_code', employee.departament_code)"
                                    >
                            </ng-select>
                        </td>
                        <td class="text-nowrap ">
                            <input type="text" class="form-control form-control input-sm" #fmr [value]="employee.fmr_employee"  
                            (keyup.enter)="updateValueSalary(employee, 'fmr_employee', fmr.value,2)"
                            (blur)="updateValueSalary(employee, 'fmr_employee', fmr.value,2)">
                        </td>
                        @for(account of independentAccountPlanVersion; track account.id){
                        @if(account.is_visible){
                        @if(findEmployeeAccountValue(employee.accounts,'fmr_employee')==0&&!account.block||!account.block&&!account.missionary_block||findEmployeeAccountValue(employee.accounts,'fmr_employee')>0&&!account.missionary_block){
                        <td class="align-middle text-nowrap text-center">
                            @if(account.type_input=='text'){
                            <input class="form-control form-control input-md" (click)="toggleInput(accountInput.value);selectAll($event)" (focus)="toggleInput(accountInput.value)"
                                #accountInput type="text"
                                [value]="getAccountValue(employee, account.account_code,account,2) | number: '1.0-0'"
                                (blur)="updateValueAccounts(employee, account.account_code, accountInput.value,2)"                   
                                (keyup.enter)="updateValueAccounts(employee, account.account_code, accountInput.value,2)"
                                appNumberFormat>
                            }@else{
                                <input type="checkbox" class="form-check-input" #apv 
                                (change)=" updateValueAccountsCheck(employee, account.account_code, apv.checked,2)" [checked]="getAccountValue(employee, account.account_code,account.calculation,2) " />
                            }
                        </td>
                        }@else {
                        <td class="align-middle text-nowrap bg-light">
                            <span>{{getAccountValue(employee, account.account_code,account) | number: '1.0-0'}}</span>
                        </td>
                        }
        
                        }
                    }
                        <td class="text-nowrap bg-light">$ {{employee.totales | number:'1.0-0'}}</td>
                    </tr>
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <th class="sticky-column"></th>
                        <th class="sticky-column-2">Sub Totales</th>
                        <th class="text-center">-</th>
                        <th class="text-center">-</th>
                        @for(account of independentAccountPlanVersion; track i;let i= $index){
                            @if(account.is_visible){
                            <th class="align-middle text-nowrap">
                              <span>{{ getSubtotalDependent(account.account_code)| number: '1.0-0'}}</span>
                            </th>
                            }
        
                        }
                        <th class="align-middle text-nowrap">
                        </th>
        
                    </tr>
                </tfoot>
            </table>
        </div>
        
       
    </div>


</div>}




<ng-template #modal_account let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header">
        <h5 class="modal-title">tabla draggrag</h5>
        <button (click)="d('Cross click')" type="button" class="btn-close bt-xs" data-dismiss="modal" tabindex="7">
            <span>&times;</span>
        </button>
    </div>
    <div class="modal-body">
       <app-setting-field-salary></app-setting-field-salary>
          
    </div>
    
</ng-template>



<ng-template #modal_setting let-c="close" let-d="dismiss" let-modal>

    <div class="modal-header">
        <h5 class="modal-title">Configurar Sueldos</h5>
        <button (click)="d('Cross click')" type="button" class="btn-close bt-xs" data-dismiss="modal" tabindex="7">
            <span>&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="panel-body panel-form">
            <form>
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Código</label>
                    <div class="col-md-8">
                        <input type="text" names="names" class="form-control" tabindex="1" />
                    </div>
                </div>&nbsp;
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Nombre</label>
                    <div class="col-md-8">
                        <input type="text" names="names" class="form-control" tabindex="2" />
                    </div>
                </div>&nbsp;

            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="d('Cross click')"><i
                class="fas fa-times"></i>&nbsp; Cerrar</button>
        <button type="button" class="btn btn-primary btn-sm" tabindex="4"><i
                class="fas fa-save"></i>&nbsp;Guardar</button>

    </div>
</ng-template>