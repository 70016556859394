import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Global } from '@services/core/Global';
import { StorageService } from '@services/core/storage.service';

@Injectable({
  providedIn: 'root'
})
export class BudgetGenerateService {
  private url: string;
  public urlEmployeeType:string;
  public token;
  private storageService = inject(StorageService)
  
  constructor(
    private _http: HttpClient

  ) { 
     this.url = Global.url_app;
     this.token = this.storageService.getJsonValue("token") 
     this.urlEmployeeType = 'assets/data/type-employee.json';
    }
/**tab cuentas */
/**tabulacion de sueldo */
  getSalaries(params): Observable<any> {
    let headers = new HttpHeaders()
     .set('Content-Type', 'application/json')
     .set('Authorization', this.token);
     let result=this._http.get(this.url+'salaries/'+params, { headers: headers });
   return result
 }

 getAccountPlanVersion(params): Observable<any> {
  let headers = new HttpHeaders()  
   .set('Content-Type', 'application/json')
   .set('Authorization', this.token);
   let result=this._http.get(this.url+'account-plan-version/'+params, { headers: headers });
 return result
}

updateAccountPlanVersion(params): Observable<any> {
  let headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Authorization', this.token);
  return  this._http.put(this.url+"account-plan-version/"+params.id, params,{ headers: headers })
}


saveAccountPlanVersion(body): Observable<any> {
  let headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Authorization', this.token)   
  return  this._http.post(this.url+"account-plan-version",body, { headers: headers})
}

deleteAccountPlanVersion(params): Observable<any> {
  let headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Authorization', this.token);
  return  this._http.delete(this.url+"account-plan-version/"+params.id,{ headers: headers })
}

/** trae las cuentas*/

getAccount(): Observable<any> {
  let headers = new HttpHeaders()  
   .set('Content-Type', 'application/json')
   .set('Authorization', this.token);
   let result=this._http.get(this.url+'account', { headers: headers });
 return result
}



getDepartament(): Observable<any> {
  let headers = new HttpHeaders()
   .set('Content-Type', 'application/json')
   .set('Authorization', this.token);
   let result=this._http.get(this.url+'departament', { headers: headers });
 return result
}

  updateSalaries(params): Observable<any> {
    let headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', this.token);
    return this._http.put(this.url+"salaries",params,{ headers: headers })
  }

  updateAccount(params): Observable<any> {
    let headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', this.token);
    return  this._http.put(this.url+"account-salary/"+params.id, params,{ headers: headers })
  }


  getSubtotal(params): Observable<any> {
    let headers = new HttpHeaders()
     .set('Content-Type', 'application/json')
     .set('Authorization', this.token);
     let result=this._http.get(this.url+'salaries-subtotal/'+params, { headers: headers });
   return result
  }

  /**items travel */

  
 getItemsTravel(params): Observable<any> {
  let headers = new HttpHeaders()  
   .set('Content-Type', 'application/json')
   .set('Authorization', this.token);
   let result=this._http.get(this.url+'items-travel/'+params, { headers: headers });
 return result
}


getEmployeeTravel(params): Observable<any> {
  let headers = new HttpHeaders()  
   .set('Content-Type', 'application/json')
   .set('Authorization', this.token);
   let result=this._http.get(this.url+'employee-travel/'+params, { headers: headers });
 return result
}



updateEmployeeTravel(params): Observable<any> {
  let headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Authorization', this.token);
  return  this._http.put(this.url+"employee-travel/"+params.id, params,{ headers: headers })
}


  /**tab variable */

  getDiverse(params): Observable<any> {
    let headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', this.token)   
    return  this._http.get(this.url+"employee"+params, { headers: headers})
 }

 getDiverseAssinet(): Observable<any> {
  let headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', this.token)   
    const type=encodeURIComponent('FUNC')
    return  this._http.get(this.url+"aasinet-denominational-account/"+type, { headers: headers})
}

 getChildren(params): Observable<any> {
  let headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', this.token)   
    return  this._http.get(this.url+"children"+params, { headers: headers})
}


saveChildren(body): Observable<any> {
  let headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Authorization', this.token)   
  return  this._http.post(this.url+"children",body,{ headers: headers})
}

saveDiverse(body): Observable<any> {
  let headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Authorization', this.token)   
  return  this._http.post(this.url+"employee",body, { headers: headers})
}


updateChildren(params): Observable<any> {
  let headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Authorization', this.token)   
  return  this._http.put(this.url+"children/"+params.id,params,{ headers: headers})
}

updateDiverse(params): Observable<any> {
  let headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Authorization', this.token)   
  return  this._http.put(this.url+"employee/"+params.id,params,{ headers: headers})
}


deleteChildren(params): Observable<any> {
  let headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Authorization', this.token)   
  return  this._http.delete(this.url+"children/"+params.id,{ headers: headers})
}

deleteDiverse(body): Observable<any> {
  let headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Authorization', this.token)   
  return this._http.delete(this.url + "employee", {
    headers: headers,
    body: body
  });
}


/**trae los tipo de empleados */
getEmployeeType(): Observable<any> {
  let headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Authorization', this.token)   
  return  this._http.get(this.url+"employee-type", { headers: headers})
}

/**actualiza el toda la prima. ATENCION FALTA IMPLEMENTAR METODO*/
updatePremiumChildren(): Observable<any> {
  let headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Authorization', this.token)   
  return  this._http.get(this.url+"premium", { headers: headers})
}


}
