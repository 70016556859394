import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, inject, effect, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@services/core/user.service';
import { BudgetGenerateService } from '@services/budget-generate.service';
import { Entity } from '../budget-generate.interface';
import Swal from 'sweetalert2'
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { LOCALE_ID } from '@angular/core';
import { NumberFormatDirective } from 'app/directives/number-format.directive';
import { EmployeeModel } from 'app/model/employee.model';
import { Parser } from 'expr-eval';
import { SettingFieldTravelComponent } from './setting-field/setting-field-travelcomponent';


@Component({
  selector: 'app-travel',
  standalone: true,
  imports: [DecimalPipe, NgSelectModule, FormsModule, NumberFormatDirective, SettingFieldTravelComponent, NgbModule, CommonModule],
  providers: [NgbModal, { provide: LOCALE_ID, useValue: 'es-ES' }],
  templateUrl: './travel.component.html',
  styleUrl: './travel.component.css'
})
export class TravelComponent {
  @ViewChild('modal_account', { static: true }) modalAccount: any;
  @ViewChild('modal_setting', { static: true }) modalSetting: any;
  private modalRef: NgbModalRef;
  private _identityService = inject(UserService);
  public dataDepartament;
  public ngSelectedOld: string;
  public inputOld;
  public dataListDiverseDependent;
  public dataListDiverseIndependent;
  public subtotalDependent;
  public subtotalIndependent;
  private entitySelected: Entity;
  public codeEntity;
  public txtFindDependent;
  public txtFindEmployeeType;
  public txtFindIndependent;
  public budgetService = inject(BudgetGenerateService)
  public dependentEmployee = [];
  public dependentEmployeeTemp;
  public independentEmployee = []
  public independentEmployeeTemp;
  public dependentItemsTravel;
  public independentItemsTravel;
  public filter: EmployeeModel = new EmployeeModel();
  public navTab = 'dependientes'
  public rows =Array.from({length: 15}, (_, i) => i + 1);
  public cols =Array.from({length: 5}, (_, i) => i + 1);
  public isLoading = true;
  public activeDependent=false
  public activeIndependent=false
  public title='Presupuesto'
  dataEmployeeType;




  constructor(private modalService: NgbModal

  ) {


    effect(() => {
      this.entitySelected = this._identityService.sharingEntity();
      if (this.entitySelected && Object.keys(this.entitySelected).length > 0) {
        this.getEmployeeTravelDependent()
        this.getEmployeeTravelIndependent()
        this.getItemsTravel()
        this.getEmployeeTravelSubtotalDependent()
        this.getEmployeeTravelSubtotalIndependent()
        this.ngOnInit()     
      }

    })

  }


  ngOnInit() {
    /**aqui podemos recibir los valores desde la tabla budget_version */
   
    this.filter.year = 2024;
    this.filter.version = 1;
    this.getEmployeeType()
  }

  openModalAccount() {
    this.open(this.modalAccount, { size: 'xl' })
  }

  openModalSetting() {
    this.open(this.modalSetting)
  }

  /*abre los modales */
  open(content, size?) {
    this.modalRef = this.modalService.open(content, size);
  }

  /*cierra los modales */
  close() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  /**Selecciona todo el texto del input */
  selectAll(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.select();
  }


  where() {
    let queryParams = '';
    if (this.filter) {
      queryParams = Object.keys(this.filter)
        .map(key => `${this.filter[key as keyof EmployeeModel]}`)
        .join('/');
    }
    return queryParams ? `${queryParams}` : '';
  }



  querySubtotal(employeeType) {
    if (employeeType === 1) {

      this.getEmployeeTravelSubtotalDependent()
    }
    else {
      this.getEmployeeTravelSubtotalIndependent()
    }
  }


  notification(title) {
    const Toast = Swal.mixin({
      icon: 'success',
      title: title,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2500,
      timerProgressBar: true,
    });
    Toast.fire();
  }


  updateValueSalary(employee, input, newValue, employeeType) {
    const currentValue = employee[input];
    if (newValue && currentValue?.toString() != newValue) {
      employee[input] = newValue;
      const account = this.setEmployeeItemTravel(employee, input);
      account.value = newValue;
      this.budgetService.updateSalaries(employee).subscribe(res => {
        this.updateFmrZero(employee, input, newValue, employeeType)
        this.querySubtotal(employeeType)
        this.notification('Actualizado');
      })
    }
    else {
      const account = this.setEmployeeItemTravel(employee, input);
      account.value = currentValue


    }
  }


  updateValueSalaryNgSelect(employee, input, newValue) {
    employee[input] = newValue
    this.budgetService.updateSalaries(employee).subscribe(res => {
    })

  }


  updateValueAccounts(employee, code, value, employeeType) {
    value = value.replace(/\./g, '').replace('.', ',');
    if (this.inputOld && value != this.inputOld) {
      const item = this.setEmployeeItemTravel(employee, code);
      item.value = value
      this.budgetService.updateEmployeeTravel({ id: item?.id, value: value }).subscribe(res => {
        this.querySubtotal(employeeType)
        this.notification('Actualizado');
      })

    }
    this.inputOld = value;
  }


  updateValueAccountsCheck(employee, code, value, employeeType) {
    const account = this.setEmployeeItemTravel(employee, code);
    this.budgetService.updateEmployeeTravel({ id: account.id, value: value }).subscribe(res => {
      const account = employee.items_travel.find(acc => acc.code == code);
      account.value = value;
      this.notification('Actualizado');
    })
  }


  whereUpdateEmupdateEmployeeTravel(employee, code) {
    return {
      code: code,
      employee_code: employee['employee_code'],
      year: employee['year'],
      version: employee['version']
    }

  }

  // Divide la fórmula en sus componentes, incluyendo códigos, variables, números y operadores.
  splitFormula(formula) {
    try {
      const regex = /(\{\d+\}|\{\w+\}|\d+(?:\.\d+)?|\+|\-|\*|\/|\(|\))/g;
      return formula.match(regex).filter(Boolean);
    } catch {
      return [];
    }
  }

  // Busca el valor de una cuenta específica dentro del array de cuentas de un empleado.  
  findEmployeeItemTravelValue(employeeItemsTravel, code) {
   let value = 0;
    if (Array.isArray(employeeItemsTravel)) {
      let item = employeeItemsTravel.find(i => i.code == code);
      if (item) {
        value = item.value;
      }
    }
    return value
  }

  // Reemplaza los códigos en la fórmula por sus valores correspondientes del array de cuentas del empleado.
  replaceCodesWithValues(formulaArray, employeeItemsTravel) {
    return formulaArray.map(item => {
      const match = item.match(/^\{([\w-]+)\}$/);
      let value = item
      if (match) {// Si el item es un código en el formato {código}, busca el valor      
        const code = match[1]; // Extrae el código numérico        
        value = this.findEmployeeItemTravelValue(employeeItemsTravel, code);
      }
      return value;
    });
  }

  replacePercentSyntax(formulaArray) {
    return formulaArray.map(item =>
      item.endsWith('%')
        ? (parseFloat(item.slice(0, -1)) / 100).toString()
        : item
    );
  }

  /**evaluamos q las formulas no tengan ningun script *//** Elimina palabras peligrosas de una fórmula */
  isSafeFormula(formula) {
    const unsafeKeywords = ['alert', 'eval', 'console', 'document', 'window', 'setTimeout', 'setInterval'];
    unsafeKeywords.forEach(keyword => {
      const regex = new RegExp(keyword, 'gi'); 
      formula = formula.replace(regex, '');
    });
    return formula;
  }

  // Convierte el array de la fórmula en una cadena y la evalúa para obtener el resultado final.
  executeCalculation(formulaArray) {
    const parser = new Parser();
    const formulaString = formulaArray.join(' ');
     try {
      let result = Math.round(parser.evaluate(formulaString))
      return result;
    } catch (error) {
      return undefined;
    }
  }

  /** */
  updateValueCalculated(employee, code, valueNew, employeeType) {
    let valueOld = (this.findEmployeeItemTravelValue(employee.items_travel, code));
    if (valueNew!=undefined && valueNew != valueOld) {
      const item = this.setEmployeeItemTravel(employee, code);
      item.value = valueNew
      this.budgetService.updateEmployeeTravel({ id: item.id, value: valueNew }).subscribe(res => {
       this.querySubtotal(employeeType)
      })
    }
  }
  


  setEmployeeItemTravel(employee, code) {
    return employee.items_travel?.find(i => i.code == code);
  }



  //realiza el cálculo.
  calculation(employee, itemTravelCode, itemTravel, employeeType) {
    const conditional=this.parseConditional(itemTravel.calculation)
    if (conditional) {

      let resultCalculation =this.executeConditional(conditional, employee.items_travel)
           this.updateValueCalculated(employee, itemTravelCode, resultCalculation, employeeType)
    }
    else {
      const formulaArray = this.splitFormula(itemTravel.calculation);
      const formulaWithValues = this.replaceCodesWithValues(formulaArray, employee.items_travel);
      let resultCalculation = this.executeCalculation(formulaWithValues);
      this.updateValueCalculated(employee, itemTravelCode, resultCalculation, employeeType)
    }
  }

/*
  calculateTotal(totalFormula, employeeItemsTravel) {
    const regex = /total\(([^;]+)\)/;
    const match = totalFormula.match(regex);
    if (!match) return 0;
  
    const condition = match[1].trim();
    // Filtra y suma los valores que cumplen con la condición
    const total = employeeItemsTravel
      .filter(item => eval(this.replaceConditionalCodesWithValues(condition, { [item.code]: item.value }))) // Evalúa la condición para cada item
      .reduce((sum, item) => sum + parseFloat(item.value), 0);
  
    return total;
  }
*/
  
// Detecta  si exite condicional if(condición; true; false) puede procesar if anidados
parseConditional(formula) {
  const regex = /if\(([^;]+);(.+);(.+)\)/;
  const match = formula.match(regex);
  let condicional = null;

  if (match) {
    const condition = match[1].trim();
    let ifTrue = match[2].trim();
    let ifFalse = match[3].trim();

    // Verificar si ifTrue contiene otro "if" anidado
    let hasNestedIf = false; // Inicializamos en falso

    // Verificamos si hay "if" anidados en ifTrue
    if (this.isNestedIf(ifTrue)) {
      ifTrue = this.parseConditional(ifTrue); // Procesamos el if anidado recursivamente
      hasNestedIf = true;
    }

    // Verificamos si hay "if" anidados en ifFalse
    if (this.isNestedIf(ifFalse)) {
      ifFalse = this.parseConditional(ifFalse); // Procesamos el if anidado recursivamente
      hasNestedIf = true;
    }

    // Crear la estructura del "if"
    condicional = {
      condition,
      ifTrue,
      ifFalse,
      hasNestedIf
    };
  }

  return condicional; // Devuelve null si no es un "if" válido
}

// Función para verificar si un string tiene un "if" anidado
isNestedIf(str) {
  const nestedIfRegex = /if\(([^;]+);(.+);(.+)\)/;
  return nestedIfRegex.test(str);
}



/**remplaza el codigo por su valor */
  replaceConditionalCodesWithValues(condition, employeeItemsTravel){
    return  condition?.replace(/\{(\d+)\}/g, (_, code) => {
      return this.findEmployeeItemTravelValue(employeeItemsTravel, code);
    });
  }


/**evalua el condicional y determina si es true o false */
  evaluateCondition(condition, employeeItemsTravel) {
    const conditionWithValues=this.replaceConditionalCodesWithValues(condition, employeeItemsTravel)
    try {
      return eval(conditionWithValues); 
    } catch {
      return false; 
    }
  }

  /**ejecutamos la formula segun sea el caso */
  executeConditional(parsedConditional, employeeItemsTravel) {
  const { condition, ifTrue, ifFalse, hasNestedIf } = parsedConditional;

  // Evaluamos la condición principal
  const conditionResult = this.evaluateCondition(condition, employeeItemsTravel);

  // Determinamos la fórmula a ejecutar (si es ifTrue o ifFalse)
  let selectedFormula = conditionResult ? ifTrue : ifFalse;
  // Si el "if" tiene un "if" anidado, procesamos recursivamente
  if (hasNestedIf) {
      return selectedFormula = this.executeConditional(selectedFormula, employeeItemsTravel);
  }
  const formulaArray = this.splitFormula(selectedFormula);
  const formulaWithValues = this.replaceCodesWithValues(formulaArray, employeeItemsTravel);
  let resultCalculation = this.executeCalculation(formulaWithValues);
  return resultCalculation;
}

  /** dejamos solo las cuentas que depende de fmr*/
  accountDeleteConst(employee) {
    const employeeCopy = [...
      employee.items_travel.filter(account => account.fmr_applied === true)]
    return employeeCopy
  }


  /**actualiza todas las cuentas que depende d fmr */
  updateFmrZero(employee, accountCode, value, employeeType) {
    const account = this.accountDeleteConst(employee)
    if (accountCode == 'fmr_employee' && value == 0) {
      account.map(a => {
        const itemAccount = this.setEmployeeItemTravel(employee, a.code);
        itemAccount.value = 0;
        this.budgetService.updateEmployeeTravel({ id: a.id, value: 0 }).subscribe(res => {
          this.querySubtotal(employeeType)
        })
      })
    }
  }

  // Obtiene el valor de una cuenta
  getAccountValue(employee, code, item_travel, employeeType) {
    if (item_travel?.calculation) {
      this.calculation(employee, code, item_travel, employeeType)
    }
    return this.findEmployeeItemTravelValue(employee.items_travel, code);
  }


  // Obtiene el valor de una cuenta
  getSubtotalDependent(accountCode) {
    let account = this.subtotalDependent?.find(acc => acc.code == accountCode);
    return account ? account.sub_total : null;

  }

  /**trae los datos de los dependientes */
  getEmployeeTravelDependent() {
    delete this.filter.type_id;
    let where = this.where();
    this.budgetService.getEmployeeTravel(where).subscribe(res => {
      this.dependentEmployee = res;
      this.dependentEmployeeTemp = JSON.parse(JSON.stringify(res));
      this.dataListDiverseDependent = this.ngSelectDiverse(res)
      this.isLoading =false;
      Swal.close()
    })
  }


  /**trae los datos de los independientes */
  getEmployeeTravelIndependent() {
    delete this.filter.type_id; //this.filter.type_id = 2;
    let where = this.where();
    this.budgetService.getEmployeeTravel(where).subscribe(res => {
      this.independentEmployee = res;
      this.independentEmployeeTemp = res;
      this.dataListDiverseIndependent = this.ngSelectDiverse(res)
      Swal.close()
    })
  }

 

  /**trae los datos de los subtotal de independientes */
  getEmployeeTravelSubtotalIndependent() {
    this.filter.type_id = 2;
    let where = this.where();
    this.budgetService.getSubtotal(where).subscribe(res => {
      this.subtotalIndependent = res
    })
  }

  /**trae los datos de los subtotal de dependientes */
  getEmployeeTravelSubtotalDependent() {
    this.filter.type_id = 1;
    let where = this.where();
    this.budgetService.getSubtotal(where).subscribe(res => {
      this.subtotalDependent = res
    })
  }



  /*al momento de dar un click almacena el valor anterior del input en otra funcion se compara el valor nuevo con el anterior */
  toggleInput(value) {
    this.inputOld = value.replace(/\./g, '').replace('.', ',');
  }


  /*mapea los datos del ng-select */
  ngSelectDiverse(diverse) {
    let newData = diverse.map((d) => {
      return {
        diverse: d.employee_code,
        nameDiverse: d.employee_code + ' - ' + d.names,
        name: d.names
      }
    });
    return newData
  }


  /**al momento de cerrar el ng select de busqueda busca actualiza la tabla de diversos  */

  changeNgSelectDependent() {
   if (this.txtFindDependent.length==0) {
      this.dependentEmployee = this.dependentEmployeeTemp;
    } else {
        this.dependentEmployee = this.dependentEmployeeTemp.filter(d => {
           return this.txtFindDependent.includes(d.employee_code); 
      })
    }

  }


  /**al momento de cerrar el ng select de busqueda busca actualiza la tabla de diversos  */
  changeNgSelectIndependent() {
    if (!this.txtFindIndependent) {
      this.independentEmployee = this.independentEmployeeTemp;
    } else {
      this.independentEmployee = this.independentEmployee.filter(d => d.employee_code == this.txtFindIndependent);
    }

  }


  /**trae el plan de cuentas */
  getItemsTravel() {  
    delete this.filter.type_id;
    this.budgetService.getItemsTravel(this.where()).subscribe(res => {
      this.dependentItemsTravel = res.filter(r => r.employee_type === 1)
      this.independentItemsTravel = res.filter(r => r.employee_type === 2)
      this.cols=Array.from({length:this.dependentItemsTravel.length}, (_, i) => i + 1);
      Swal.close()
    })
  }

    /*trae los datos tipo empleado en el servicio de budget  */
    getEmployeeType() {
      this.budgetService.getEmployeeType().subscribe((res) => {
        this.dataEmployeeType = res;
      }, (error => console.error(error)));
    }
    

    /**al momento de cerrar el ng select de busqueda busca actualiza la tabla de diversos  */

  changeNgSelectEmployeeType() {
    if (this.txtFindEmployeeType.length==0) {
       this.dependentEmployee = this.dependentEmployeeTemp;
     } else {
         this.dependentEmployee = this.dependentEmployeeTemp.filter(d => {
            return this.txtFindEmployeeType.includes(d.type_id); 
       })
     }
 
   }
 


}
