<!-- BEGIN #sidebar -->
<div id="sidebar" class="app-sidebar" [attr.data-bs-theme]="appSettings.appSidebarLight ? '' : 'dark'" [ngClass]="{ 
	'app-sidebar-transparent': this.appSidebarTransparent,
	'app-sidebar-grid': this.appSidebarGrid
}">
  <!-- BEGIN scrollbar -->
  <ng-scrollbar class="app-sidebar-content" [ngClass]="{ 'h-100': appSettings.appSidebarFixed }" #sidebarScrollbar
    (scroll)="onScroll($event)">
    <ng-container *ngTemplateOutlet="sidebarNav"></ng-container>
  </ng-scrollbar>
  <!-- END scrollbar -->

  <ng-template #sidebarNav>
    <!-- BEGIN menu -->
    <div class="menu">
      <div class="menu-item menu-profile">
        <div class="menu-profile-cover custom-shadow"></div>
        <div class="menu-profile-info">
          @if(entity){
          <div class="d-flex align-items-center mb-2">
            <div class="flex-grow-1 ">
              <span>{{ entity.name }}</span>
            </div>
          </div>
          <small class="mt-1">[ {{entity.code}} ] - {{entity.campus}}</small>
          }
        </div>
      </div>

      <div class="menu-header">Navegación</div>

      <!-- render sidebarMenuNav -->
      <ng-template #sidebarMenuNav let-menu="menu">
        <div class="menu-icon-img" *ngIf="menu.img"><img src="{{ menu.img }}" /></div>
        <div class="menu-icon" *ngIf="menu.icon"><i class="{{ menu.icon }}"></i></div>
        <div class="menu-text" *ngIf="menu.title">
          {{ menu.title }}
          <span class="menu-label" *ngIf="menu.label">{{ menu.label }}</span>
        </div>
        <div class="menu-badge" *ngIf="menu.badge">{{ menu.badge }}</div>
        <div class="menu-caret" *ngIf="menu.caret"></div>
      </ng-template>

      <!-- render sidebarSubMenuNav -->
      <ng-template #sidebarSubMenuNav let-menu="menu">
        <div class="menu-text" *ngIf="menu.title">
          {{ menu.title }}
          <i class="fa fa-paper-plane text-theme ms-5px" *ngIf="menu.highlight"></i>
        </div>
        <div class="menu-caret" *ngIf="menu.caret"></div>
      </ng-template>

      <!-- sidebar menu -->
      @for(menu of menus;track menu){
      <div class="menu-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
        [ngClass]="{ 'expand': menu.state == 'expand',  'has-sub': menu.submenu?.length > 0, 'd-none': menu.hide }"
        #rla="routerLinkActive">

        <!-- Cuando hay submenu -->
        <a class="menu-link" *ngIf="menu.submenu?.length > 0"
          (mouseenter)="showAppSidebarFloatSubMenu(menu.submenu, $event)" (mouseleave)="hideAppSidebarFloatSubMenu()">
          <ng-container *ngTemplateOutlet="sidebarMenuNav; context: {menu: menu}"></ng-container>
        </a>

        <!-- Cuando no hay submenu -->
        <a class="menu-link" *ngIf="!menu.submenu?.length" [routerLink]="menu.url">
          <ng-container *ngTemplateOutlet="sidebarMenuNav; context: {menu: menu}"></ng-container>
        </a>

        <!-- submenu lvl 1 -->
        <div class="menu-submenu" *ngIf="menu.submenu"
          [style.display]="(menu.state == 'expand' && !appSettings.appSidebarMinified) ? 'block' : (menu.state == 'collapse') ? 'none' : ''">
          @for(menu1 of menu.submenu;track menu1){
          <div class="menu-item" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}"
            [ngClass]="{ 'expand': menu1.state == 'expand', 'closed': menu1.state == 'collapsed', 'has-sub': menu1.submenu, 'd-none': menu1.hide }"
            #rla1="routerLinkActive">
            <a class="menu-link" *ngIf="menu1.submenu"
              (click)="(menu1.submenu) ? expandCollapseSubmenu($event,menu1, menu.submenu, rla1) : ''">
              <ng-template *ngTemplateOutlet="sidebarSubMenuNav; context: {menu: menu1}"></ng-template>
            </a>
            <a class="menu-link" *ngIf="!menu1.submenu" [routerLink]="menu1.url">
              <ng-template *ngTemplateOutlet="sidebarSubMenuNav; context: {menu: menu1}"></ng-template>
            </a>

            <!-- submenu lvl 2 -->
            <div class="menu-submenu" *ngIf="menu1.submenu"
              [style.display]="(menu1.state == 'expand' && !appSettings.appSidebarMinified) ? 'block' : (menu1.state == 'collapse') ? 'none' : ''">
              <div class="menu-item" *ngFor="let menu2 of menu1.submenu" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
                [ngClass]="{ 'expand': menu2.state == 'expand', 'closed': menu2.state == 'collapsed', 'has-sub': menu2.submenu, 'd-none': menu2.hide }"
                #rla2="routerLinkActive">
                <a class="menu-link" *ngIf="menu2.submenu"
                  (click)="(menu2.submenu) ? expandCollapseSubmenu($event,menu2, menu1.submenu, rla2) : ''">
                  <ng-template *ngTemplateOutlet="sidebarSubMenuNav; context: {menu: menu2}"></ng-template>
                </a>
                <a class="menu-link" *ngIf="!menu2.submenu" [routerLink]="menu2.url">
                  <ng-template *ngTemplateOutlet="sidebarSubMenuNav; context: {menu: menu2}"></ng-template>
                </a>

                <!-- submenu lvl 3 -->
                <div class="menu-submenu" *ngIf="menu2.submenu"
                  [style.display]="(menu2.state == 'expand' && !appSettings.appSidebarMinified) ? 'block' : (menu2.state == 'collapse') ? 'none' : ''">
                  <div class="menu-item" *ngFor="let menu3 of menu2.submenu" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                    [ngClass]="{ 'expand': menu3.state == 'expand', 'closed': menu3.state == 'collapsed', 'has-sub': menu3.submenu, 'd-none': menu3.hide }"
                    #rla3="routerLinkActive">
                    <a class="menu-link" *ngIf="menu3.submenu"
                      (click)="(menu3.submenu) ? expandCollapseSubmenu($event,menu3, menu2.submenu, rla3) : ''">
                      <ng-template *ngTemplateOutlet="sidebarSubMenuNav; context: {menu: menu3}"></ng-template>
                    </a>
                    <a class="menu-link" *ngIf="!menu3.submenu" [routerLink]="menu3.url">
                      <ng-template *ngTemplateOutlet="sidebarSubMenuNav; context: {menu: menu3}"></ng-template>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>

      }
      <!-- ocultar menu-->
      <div class="menu-item d-flex">
        <a href="javascript:;" class="app-sidebar-minify-btn ms-auto" (click)="toggleAppSidebarMinified()"><i
            class="fa fa-angle-double-left"></i></a>
      </div>

    </div>

  </ng-template>
</div>