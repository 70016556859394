import { Routes } from '@angular/router';
import { HomePage } from './pages/home/home';
import { ErrorPage } from './pages/error/error';
import { MaintenanceUserComponent } from './module/maintenance-user/maintenance-users.component';
import { BudgetGenerateComponent } from '@modules/budget-generate/budget-generate.component';
import { TabVariableComponent } from '@modules/budget-generate/tab-varible/tab-variable.component';
import { ChildrenComponent } from '@modules/budget-generate/children/children.component';
import { EmployeeComponent } from '@modules/budget-generate/employee/employee.component';
import { TabulationSalaryComponent } from '@modules/budget-generate/tab-account/tabulation-salary/tabulation-salary.component';
import { TravelComponent } from '@modules/budget-generate/travel/travel.component';

export const routes: Routes = [
    //{ path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: '', component: HomePage, data: { title: 'Home' } },
    { path: 'employee', component:EmployeeComponent, data: { title: 'Home' } },
    { path: 'children', component:ChildrenComponent, data: { title: 'Home' } },    
    { path: 'tab-salary', component:TabulationSalaryComponent, data: { title: 'Home' } },
    { path: 'travel', component:TravelComponent, data: { title: 'Home' } },
    { path: 'reporte_presupuesto', component:BudgetGenerateComponent, data: { title: 'Home' } },
    { path: 'mantenedor-usuario', component: MaintenanceUserComponent, data: { title: 'Home' } },

   { path: '**', component: HomePage, data: { title: '404 Error' } }
];
