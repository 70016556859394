import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, inject, effect, signal, CreateEffectOptions } from '@angular/core';
import { TabulationSalaryComponent } from './tab-account/tabulation-salary/tabulation-salary.component';
import { TabVariableComponent } from './tab-varible/tab-variable.component';
import { AppComponent } from 'app/app.component';
import { TravelComponent } from './travel/travel.component';
import { SettingFieldSalaryComponent } from './tab-account/tabulation-salary/setting-field/setting-field-salary.component';

@Component({
  selector: 'app-budget-generate',
  standalone: true,
  imports: [AppComponent,
            TabVariableComponent,            
            TabulationSalaryComponent,
            TravelComponent,
            SettingFieldSalaryComponent,
            CommonModule
          ],
  providers: [],
  templateUrl: './budget-generate.component.html',
  styleUrl: './budget-generate.component.css'
})
export class BudgetGenerateComponent {
  public navTab = 'resumen'
  public tab = 'tabSueldo'
  year=new Date().getFullYear();
  

  ngOnInit() {


  }



}
