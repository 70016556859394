

<h1 class="page-header">Page Header <small>header small text goes here...</small></h1>


<!-- begin panel -->
<panel title="Panel Title here" [noButton]="true">

  <div class="panel-body">
    Panel Content 
  </div>

</panel>
<!-- end pane -->