<!-- presupuesto -->

<h1 class="page-header"><i class="fas fa-chart-line"></i>{{title}}<small>
        {{navTab=='dependientes'&&activeDependent?'Dependientes':''+navTab=='independientes'&&activeIndependent?'independientes':''+!activeIndependent&&!activeDependent?'2025
        - 1':''}} </small></h1>
@if(activeDependent){
<div class="panel p-4">
    <app-setting-field-travel [employeeType]="1"
        (activeChange)="activeDependent=$event;title='Presupuesto'"></app-setting-field-travel>
</div>
}

<div class="panel p-4 ">
    <div class="row mb-3 mt-2">
        <div class="col-4 col-md-4">
            <ng-select [items]="dataListDiverseDependent" [selectableGroup]="true" [hideSelected]="false"
                bindLabel="nameDiverse" [multiple]="true" [closeOnSelect]="false" [dropdownPosition]="'bottom'"
                bindValue="diverse" (change)="changeNgSelectDependent()" placeholder="Buscar por diverso, nombre..."
                [(ngModel)]="txtFindDependent" tabindex="1">
            </ng-select>
        </div>
        <div class="col-2 col-md-2">
            <ng-select [items]="dataEmployeeType" [selectableGroup]="true" [hideSelected]="false" bindLabel="name"
                [multiple]="true" [closeOnSelect]="false" [dropdownPosition]="'bottom'" bindValue="id"
                (change)="changeNgSelectEmployeeType()" placeholder="Tipo de empleado" [(ngModel)]="txtFindEmployeeType"
                tabindex="1">
            </ng-select>
        </div>
        <div class="col-6 col-md-6 d-flex justify-content-end">
            <a href="javascript:;" class="btn btn-success text-white" (click)="activeDependent=true"><i
                    class="fas fa-cog"></i> Configurar Viajes</a>&nbsp;
        </div>
        <br>
    </div>
    <div class="table-responsive table-data">
        <table class="table table-sm ">
            <thead>
                <tr>
                    <th class="sticky-column text-nowrap ">N° Diverso</th>
                    <th class="sticky-column-2 text-nowrap  ">Nombre Empleado</th>
                    @for(item of dependentItemsTravel; track item.id){
                    @if(true){
                    <th>
                        <div class="text-center " ngbTooltip={{item.hint}}>
                            <span class="text-nowrap">{{item.name}}</span>
                            <br>
                        </div>
                    </th>
                    }
                    }
                </tr>
            </thead>
            <tbody>
                <!--muestra una tabla cargando mientras se estan cargado los datos-->
                @if(isLoading){
                @for( i of rows ;track $index){
                <tr class="tr-custom">
                    @for( i of cols ;track $index){
                    <td>
                        <p class="p-skeleton m-1" style="width: 100%; height: 12px;"></p>
                    </td>
                    }
                </tr>
                }
                }
                @for(employee of dependentEmployee; track employee.id){
                <tr>
                    <td class="text-nowrap sticky-column align-middle bg-light text-center">
                        {{employee.employee_code}}
                    </td>
                    <td class="text-nowrap sticky-column-2 align-middle bg-light">{{employee.names}}
                        {{employee.surname}} {{employee.lastname}}</td>
                    @for(item of dependentItemsTravel; track item.id){
                    @if(item.is_visible){
                    @if(findEmployeeItemTravelValue(employee.items_travel,'fmr_employee')==0&&!item.block||!item.block&&!item.missionary_block||findEmployeeItemTravelValue(employee.items_travel,'fmr_employee')>0&&!item.missionary_block){
                    <td class="align-middle text-nowrap text-center">
                        <input class="form-control form-control input-md"
                            (click)="toggleInput(input.value);selectAll($event)" (focus)="toggleInput(input.value)"
                            #input type="text" [value]="getAccountValue(employee, item.code,item,1) | number: '1.0-0'"
                            (blur)="updateValueAccounts(employee, item.code, input.value,1)"
                            (keyup.enter)="updateValueAccounts(employee, item.code, input.value,1)" appNumberFormat>

                    </td>
                    }@else {
                    <td class="align-middle text-nowrap text-center bg-light">
                        <span>$ {{getAccountValue(employee, item.code,item) | number: '1.0-0'}}</span>
                    </td>
                    }

                    }
                    }
                </tr>
                }
            </tbody>
            <tfoot>
                <tr>
                    <th class="sticky-column"></th>
                    <th class="sticky-column-2">Sub Totales</th>
                    <th class="text-center">-</th>
                    <th class="text-center">-</th>
                    @for(item of dependentItemsTravel; track i;let i= $index){
                    @if(item.is_visible){
                    <th class="align-middle text-nowrap">
                        <span>{{ getSubtotalDependent(item.code)| number: '1.0-0'}}</span>
                    </th>
                    }

                    }
                    <th class="align-middle text-nowrap">
                    </th>

                </tr>
            </tfoot>
        </table>
    </div>
    <br>
</div>