<div class="theme-panel" [ngClass]="{ 'active': active }">
	<a href="javascript:;" (click)="toggleThemePanel()" class="theme-collapse-btn"><i class="fa fa-cog"></i></a>
		<div class="theme-panel-content">
		<h5>Apariencia del Sistema</h5>
	
		<!-- END theme-list -->
		
		<div class="theme-panel-divider"></div>
		
		<div class="row mt-10px">
			<div class="col-8 control-label text-dark fw-bold">
				<div>Modo Oscuro <span class="badge bg-primary ms-1 py-2px position-relative" style="top: -1px;">NEW</span></div>
				<div class="lh-14">
					<small class="text-dark opacity-50">
					Ajusta la apariencia para reducir el brillo y darle un descanso a tus ojos
					</small>
				</div>
			</div>
			<div class="col-4 d-flex">
				<div class="form-check form-switch ms-auto mb-0">
					<input type="checkbox" class="form-check-input" name="app-theme-dark-mode" id="appThemeDarkMode" (change)="toggleDarkMode($event)" [checked]="appThemeDarkModeCheckbox" value="1" />
					<label class="form-check-label" for="appThemeDarkMode">&nbsp;</label>
				</div>
			</div>
		</div>
		
		<div class="theme-panel-divider"></div>
		
		<!-- BEGIN theme-switch -->

		<div class="row mt-10px align-items-center">
			<div class="col-md-8 control-label text-dark fw-bold">Gradient Enabled</div>
			<div class="col-md-4 d-flex">
				<div class="form-check form-switch ms-auto mb-0">
					<input type="checkbox" class="form-check-input" name="app-gradient-enabled" id="appGradientEnabled" (change)="toggleGradientEnabled($event)" value="1" [checked]="appGradientEnabledCheckbox" />
					<label class="form-check-label" for="appGradientEnabled">&nbsp;</label>
				</div>
			</div>
		</div>
		<!-- END theme-switch -->
		
		<div class="theme-panel-divider"></div>
		
		
</div>