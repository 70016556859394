import { Component, Input, Output, EventEmitter, effect, inject, OnInit } from '@angular/core';
import { AppSettings } from '@services/settings/app-settings.service';
import { MicrosoftAuthService } from '@services/core/authentication.service';
import { UserService } from '@services/core/user.service';
declare var slideToggle: any;

@Component({
	selector: 'header',
	templateUrl: './header.component.html',
	styleUrls: ["./header.component.css"],
	standalone: true,
})
export class HeaderComponent implements OnInit{
	@Input() appSidebarTwo;
	@Output() appSidebarEndToggled = new EventEmitter<boolean>();
	@Output() appSidebarMobileToggled = new EventEmitter<boolean>();
	@Output() appSidebarEndMobileToggled = new EventEmitter<boolean>();

	protected logo = ""
	protected profilePhotoURL
	protected userData
	public entities = []
	public entitiesTemp = []
	public appSettings = inject(AppSettings)
	private userService = inject(UserService)
	private authMicrosoft = inject(MicrosoftAuthService)

	toggleAppSidebarMobile() {
		this.appSidebarMobileToggled.emit(true);
	}

	toggleAppSidebarEnd() {
		this.appSidebarEndToggled.emit(true);
	}

	toggleAppSidebarEndMobile() {
		this.appSidebarEndMobileToggled.emit(true);
	}

	toggleAppTopMenuMobile() {
		var target = document.querySelector('.app-top-menu');
		if (target) {
			slideToggle(target);
		}
	}

	toggleAppHeaderMegaMenuMobile() {
		this.appSettings.appHeaderMegaMenuMobileToggled = !this.appSettings.appHeaderMegaMenuMobileToggled;
	}



	constructor(){
		effect(()=> {
			this.userData = this.userService.userData()
			this.profilePhotoURL = this.userService.userPhoto()
			
		})
		effect(() => {
			this.entities = this.userService.sharingEntities()
			this.entitiesTemp = this.entities
		})
	}

	ngOnInit(): void {
		
	}
	
	logout() {
		this.authMicrosoft.clearSesion()
		this.authMicrosoft.logout().subscribe(res => {
			let a = document.createElement('a')
			a.href = res.url
			a.click()
		})
	}

	cambiaentidad(entity){		
		this.userService.changeEntity(entity).subscribe(res=>{
			this.userService.sharingEntity.set(entity);
		})

	}

	filtrarEntidades(event){
		const find = event.toLowerCase()
		this.entities = this.entitiesTemp.filter(entity => 
			entity.name.toLowerCase().includes(find)	|| 
			entity.code.toString().includes(find) || 
			entity.group.toLowerCase().includes(find) || 
			entity.campus.toLowerCase().includes(find)
		)
	}
}
