import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNumberFormat]',
  standalone: true
})
export class NumberFormatDirective {
  private previousValue: string = '';

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: any) {
    let value = this.el.nativeElement.value;
    if(value[value.length - 1]==='.'){
      value=value+value[value.length - 1].replace(/\./g, ',');
    }
    let rawValue = value.replace(/\./g, '').replace('.', ',');
    let formattedValue = this.formatNumber(rawValue);
    this.el.nativeElement.value = formattedValue;
    this.previousValue = formattedValue;  // Guardar el valor anterior
    event.target.value = formattedValue;
  }

  private formatNumber(value: string): string {
    let [integerPart, decimalPart] = value.split(',');
    decimalPart=decimalPart?decimalPart:'';
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let newValue=formattedInteger
    if(value.includes(',')){
      decimalPart=","+decimalPart
    }
    return newValue+decimalPart
  }
}
