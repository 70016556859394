import { Component, Input, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { AppSettings } from './service/settings/app-settings.service';
import { CommonModule, Location } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MicrosoftAuthService } from '@services/core/authentication.service';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { UserService } from '@services/core/user.service';
import { StorageService } from '@services/core/storage.service';
import Swal from 'sweetalert2'
import { AppVariablesService } from './service/app-variables.service';
import { ThemePanelComponent } from './components/theme-panel/theme-panel.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, SidebarComponent,ThemePanelComponent, CommonModule],
  providers: [MicrosoftAuthService, UserService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  @Input() code?: any
  title = 'pres.Ui';
  isLogged 
  authMicrosoft = inject(MicrosoftAuthService)
  activatedRoute = inject(ActivatedRoute)
  userService = inject(UserService)
  location = inject(Location)
  router = inject(Router)
  storageService = inject(StorageService)
  swalBackground
	swalTextColor
  constructor( private appVariablesService: AppVariablesService,
              public appSettings: AppSettings
  ) {}

  appVariables = this.appVariablesService.getAppVariables()


		
  ngOnInit() {
    this.isLogged = this.storageService.getJsonValue("logged");
    if (!this.isLogged) {
      this.handleNotLoggedIn();
    } else {
      this.handleLoggedIn();
    }


    if (this.appSettings.appDarkMode) {
			this.onAppDarkModeChanged(false);
		}
	
		if (localStorage) {
			if (localStorage['appDarkMode']) {
				this.appSettings.appDarkMode = localStorage['appDarkMode'];
				if (this.appSettings.appDarkMode) {
					this.onAppDarkModeChanged(true);
				}
			}
			if (localStorage['appGradientEnabled']) {
				this.appSettings.appGradientEnabled = (localStorage['appGradientEnabled'] === 'true') ? true : false;
			}
		}
  }
  
  private handleNotLoggedIn() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params["code"]) {
        this.getToken(params["code"]);
      } else {
        this.loginMicrosoft();
      }
    });
  }
  
  private handleLoggedIn() {
  
  const path = this.location.path().includes("?code") ? "" : this.location.path()
  this.router.navigateByUrl(path);
  this.getUserData();
  }

  // set page minified
	onAppSidebarMinifiedToggled(val): void {
		this.appSettings.appSidebarMinified = !this.appSettings.appSidebarMinified;
		if (localStorage) {
			localStorage['appSidebarMinified'] = this.appSettings.appSidebarMinified;
		}
	}

  onAppDarkModeChanged(val: boolean): void {

   	if (localStorage['appDarkMode'] === 'true') {  
      this.appSettings.appDarkMode=true;      
			document.documentElement.setAttribute('data-bs-theme', 'dark');
		} else {
			document.documentElement.removeAttribute('data-bs-theme');
      this.appSettings.appDarkMode=false; 
		}
    this.appSettings.appDarkMode
    this.appSettings.appGradientEnabled = (localStorage['appGradientEnabled'] === 'true') ? true : false;
		this.appVariables = this.appVariablesService.getAppVariables();
		this.appVariablesService.variablesReload.emit();
		document.dispatchEvent(new CustomEvent('theme-change'));
	}
	
  // hide mobile sidebar
  onAppSidebarMobileToggled(val): void {
    this.appSettings.appSidebarMobileToggled = !this.appSettings.appSidebarMobileToggled;
  }

  loginMicrosoft() {
		this.authMicrosoft.getTokenAPI().subscribe(res => {
			window.location.href = res.url;
		});
	}

  getToken(code: string) {
		try {
			this.authMicrosoft.getAccessToken(code).subscribe(res => {
				this.authMicrosoft.token.set(res.access_token)
				this.authMicrosoft.refreshToken.set(res.refresh_token);
        this.storageService.setJsonValue("logged", "true")
        this.storageService.setJsonValue("token", res.access_token)
        this.storageService.setJsonValue("refresh_token", res.refresh_token)
        if(this.router.url.includes("?code") ){
          const path = this.router.url.split("?")[0]
          this.router.navigateByUrl(path) 
        } 
				this.getUserData();
			})
		} catch (error) {
			console.error('Error al obtener el token:', error);
		}
	}

  getUserData() {
    this.swalDarkMode()
    Swal.fire({
      allowOutsideClick: false,
      showConfirmButton: false,
      background: this.swalBackground,
			color:this.swalTextColor,
		
      willOpen: () => {
        Swal.showLoading();
      },
    });
		this.authMicrosoft.decryptToken().subscribe(res => {
      if (res.error) {
        if (res.error?.code == "InvalidAuthenticationToken") {
          this.showCautionSesion()
        } else {
          Swal.fire({
            title: "Acceso Denegado",
            text: res.error,
            icon: "error",
            background: this.swalBackground,
			      color:this.swalTextColor,			
            confirmButtonText: "Salir",
            allowOutsideClick: false
          }).then(result => {
            this.authMicrosoft.logout().subscribe(res => {
              this.authMicrosoft.clearSesion()
              let a = document.createElement('a')
              a.href = res.url
              a.click()
            })
          })
        }
      } else {  
        try {
          this.userService.processUserData(res.user.user);
          this.userService.processUserPhoto(res.photo.body.data);
          this.userService.sharingEntities.set(res.user.entities);
          this.userService.sharingEntity.set(res.user.entity);
          this.userService.sharingIdentity.set(res.user.identity);
          this.userService.sharingMenu.set(res.user.menu);
          this.userService.sharingRole.set(res.user.role);
          this.isLogged = this.authMicrosoft.token;
        } catch (error) {
          console.error('Error en el procesamiento de datos:', error);
        }      

        Swal.close()
        
      }

		
		})
	}


  swalDarkMode(){	
		if(localStorage['appDarkMode'] === 'true'){
			this.swalBackground= '#333';
			this.swalTextColor='#fff';
		}
		else{			
			this.swalBackground='#fff';
			this.swalTextColor='#333';
		}
	}


  showCautionSesion() {
    this.swalDarkMode()
    Swal.fire({
      title: "Su Sesión ha expirado",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Renovar",
      cancelButtonText: "Cerrar Sesión",
      confirmButtonColor: "#3085d6",
      background: this.swalBackground,
      color:this.swalTextColor,			
     
      cancelButtonColor: "red",
      allowOutsideClick: false
    }).then(result => {
      if (result.isConfirmed) {
        this.authMicrosoft.refreshTokenAPI().subscribe(res => {
        this.storageService.setJsonValue("access_token", res.access_token)
         this.getUserData()
        })
      } else {
        this.authMicrosoft.logout().subscribe(res => {
          this.authMicrosoft.clearSesion()
          let a = document.createElement('a')
          a.href = res.url
          a.click()
        })
      }


    })
  }


	onAppThemeChanged(val: boolean): void {
		const newTheme = 'theme-' + this.appSettings.appTheme;
		for (let x = 0; x < document.body.classList.length; x++) {
			if ((document.body.classList[x]).indexOf('theme-') > -1 && document.body.classList[x] !== newTheme) {
				document.body.classList.remove(document.body.classList[x]);
			}
		}
		document.body.classList.add(newTheme);
		this.appVariables = this.appVariablesService.getAppVariables();
		this.appVariablesService.variablesReload.emit();
	}



}
