import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Global } from '@services/core/Global';
import { MicrosoftAuthService } from '@services/core/authentication.service';

@Injectable()
export class MaintenanceService {
  public url: string;
  public urlIndentity: string;
  public identity;
  public token;
  
  constructor(
    private _http: HttpClient,
    private _microsoftAuth:MicrosoftAuthService,
  ) { 
     this.url = Global.url_app;
     this.token = this._microsoftAuth.token();
    }

  findUser(params): Observable<any> {
     let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.token);
      let result=this._http.get(this.url+'user/'+params, { headers: headers });
     return result
  }


  getRole(): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.token);
     return this._http.get(this.url+'role/', { headers: headers });
  }

  
  updateUser(body): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.token);
     return this._http.put(this.url+'user/'+body._id,body, { headers: headers });
  }


  newUser(body): Observable<any> {
   let params=JSON.stringify(body)
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.token);
     return this._http.post(this.url+'user',params, { headers: headers });
  }


  getEntities(): Observable<any> {
     let headers = new HttpHeaders()
       .set('Content-Type', 'application/json')
       .set('Authorization', this.token);
      return this._http.get(this.url+'entities', { headers: headers });
   }


   getdomains(): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', this.token);
     return this._http.get(this.url+'domains', { headers: headers });
  }

   
  
   

}

 

