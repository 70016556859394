import { CommonModule } from '@angular/common';
import { Component, inject, effect, ViewChild, ElementRef } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbDatepickerModule, NgbModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@services/core/user.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgSelectModule } from '@ng-select/ng-select';
import { BudgetGenerateService } from '@services/budget-generate.service';
import { Entity } from '../budget-generate.interface';
import Swal from 'sweetalert2';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';

registerLocaleData(localeEs);


@Component({
  selector: 'app-tab-variable',
  standalone: true,
  imports: [AppComponent, CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbModule
  ],
  providers: [NgbModal,{ provide: LOCALE_ID, useValue: 'es-ES' }],
  templateUrl: './tab-variable.component.html',
  styleUrl: './tab-variable.component.scss',
})
export class TabVariableComponent {
  @ViewChild('modal_diverse', { static: true }) modalDiverse: any;
  @ViewChild('modal_children', { static: true }) modalChildren: any;
  @ViewChild('ngSelectAssinet', { static: false }) ngSelectAssinet: NgSelectComponent;
  @ViewChild('ngSelect', { static: false }) ngSelect: NgSelectComponent;
  @ViewChild('nameInput') nameInput: ElementRef;
  private modalRef: NgbModalRef;
  private _identityService = inject(UserService);
  private _budgeGenrateService = inject(BudgetGenerateService);
  private entitySelected: Entity;
  private year;
  private version;
  public dataEmployeeType;
  public dataListDiverse;
  public dataNgSelectAssinet;
  public dataDiverse;  
  public dataDiverseTemp;
  public dataChildren;
  public dataChildrenTemp=undefined;
  public txtFind;
  public tabArea = 'diverse';
  public modalHeaderDiverse: string;
  public modalHeaderChildren: string;
  public formDiverse: FormGroup;
  public formChildren: FormGroup;
  public id;
  public diverseFinded=false
  public diverseCopy;
  public isNewDiverse=false
  closeResult: string;
  modalOptions = {
    animation: true,
  };
  constructor(private modalService: NgbModal, private formBuilder: FormBuilder,) {
    effect(() => {
      this.entitySelected = this._identityService.sharingEntity();
      if (this.entitySelected && Object.keys(this.entitySelected).length > 0) {
        this.year = 2024;
        this.version = 1
        this.dataListDiverse=undefined;
        this.ngOnInit();
        this.getDiverse();       
        this.getEmployeeType();
        this.getChildren();
      }
    });
  }


  /**crear formulario reactivo diverso*/
  createFormDiverse() {
    this.formDiverse = this.formBuilder.group({
      code: ['', [Validators.required, Validators.minLength(1)]],
      names: ['', Validators.required],
      type: ['', [Validators.required]],
    });
  }

  /**crear formulario reactivo prima infantil*/
  createFormChildren() {
    this.formChildren = this.formBuilder.group({
      employee_code: [null, [Validators.required]],
      names: ['', Validators.required],
      birthday: ['', [Validators.required,this.maxDateValidator]]
    });
  }

  ngOnInit() {
    this.createFormDiverse();
    this.createFormChildren();
  }

  /**parametros de url para filtrar por año y version */
  where() {
    let query = null
    let year = '';
    let version = '';
    if (this.entitySelected) {
      year = this.year;
      version = this.version;
      query = `/${year}/${version}`
    }
    return query
  }

  /**genera los parametros de url para filtrar especificamente por un campo */
  whereFilter(filter) {
    let query = filter;
    let code = '';
    let name = '';
    let employee='';
    if (this.txtFind) {
      if(this.tabArea==='children'){
        code = encodeURIComponent('%' + this.txtFind + '%');
        name= encodeURIComponent('%' + this.txtFind + '%');
        employee= encodeURIComponent('%' + this.txtFind + '%');
        query = `${filter}/${code}/${name}/${employee}`

      }
      else{
        code = encodeURIComponent('%' + this.txtFind + '%')
        query = `${filter}/${code}`
      }      
    }
    return query
  }



/**al presionar las teclas valida q si es vacio se ejecute el metodo buscar Children */
  onKeyUp() {
		if (this.txtFind === '') {
			this.getChildren();
		}
	}


/**al presionar Enter se ejecuta el metodo buscar Children */
	onKeydown(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			this.getChildren();
		}
	}


  /**combina los datos del formDiverso con los datos q faltan para hacer la peticion en el servicio */
  newData(data) {
    data['entity_code'] = this.entitySelected?.code;
    data['year']=this.year;
    data['version']=this.version
    return data;
  }

  /*mensaje de cargando */
  loading() {
    Swal.fire({
      title: 'Cargando...',
      html: 'Por favor, espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }


  /*trae los datos diverse en el servicio de budget  */
  getDiverse() {
    this.loading();
    this._budgeGenrateService.getDiverse(this.whereFilter(this.where())).subscribe((diverso) => {
      this.dataDiverse = diverso;
       if(!this.dataListDiverse){
        this.getDiverseAssinet();
        this.dataDiverseTemp=this.dataDiverse;
        this.dataListDiverse=this.ngSelectData(diverso)
       }
      Swal.close();
    }, (error => console.error(error)));
  }


  /*trae los datos diverse en el servicio de budget  */
  getDiverseAssinet() {
    this._budgeGenrateService.getDiverseAssinet().subscribe((diverso) => {    
      this.dataNgSelectAssinet = this.mapSelectAassinet(diverso)
      Swal.close();
    }, (error => console.error(error)));
  }

/**busca los diverso  */
  findEmployee(code): boolean {
    let isActive=false;
    isActive=!this.dataDiverse.some((diverse) => { return diverse.code === code })
    return isActive
   
	}

/**onclick button children */
  toggleChildren(){
    this.tabArea='children';
    this.txtFind=undefined;
    this.dataChildren=this.dataChildrenTemp;
  }

  /**onclick button children */
  toggleDiverse(){
    this.tabArea='diverse';
    this.txtFind=undefined;
    this.dataDiverse=this.dataDiverseTemp;
    
  }


  /*mapea los datos del ng-select */
  ngSelectData(diverse) {
    let newData = diverse.map((d) => {
      return {
        diverse: d.code,
        nameDiverse: d.code + ' - ' + d.names,
        name: d.names
      }
    });
    return newData
  }

    /*mapea los datos del ng-select deshabilita los registros q ya entan registrado el diverso de empleado */
    mapSelectAassinet(diverse) {
      let newData = diverse.map((d) => {
        const name=d.names
        const isActive = this.findEmployee(d.code);
        return {
          diverse: d.code,
          nameDiverse: d.code + ' - ' + name,
          name: name,
          disabled:!isActive,
          isActive:isActive
        }
      });
      return newData
    }

   /**al momento de cerrar el ng select de busqueda busca actualiza la tabla de diversos  */
   onCloseNgSelectFind() {
    this.getDiverse()
  }


  /*trae los datos prima infantil en el servicio de budget  */
  getChildren() {
    this.loading();
    this._budgeGenrateService.getChildren(this.whereFilter(this.where())).subscribe(res => {
      if(!this.dataChildrenTemp){
        this.dataChildrenTemp=res
      }
      this.dataChildren =res
      Swal.close();
    }, (error => console.error(error)));
  }

  /*trae los datos tipo empleado en el servicio de budget  */
  getEmployeeType() {
    this._budgeGenrateService.getEmployeeType().subscribe((res) => {
      this.dataEmployeeType = res;
    }, (error => console.error(error)));
  }

  /*abre los modales */
  open(content) {
    this.modalRef = this.modalService.open(content);
  }

   /*cierra los modales */
  close() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }


  /**abre el modal del diverse */

  openModalDiverse() {
    this.isNewDiverse=true
    this.modalHeaderDiverse = 'Nuevo Diverso';
    this.formDiverse.markAsPristine();
    this.formDiverse.reset();
    this.open(this.modalDiverse);
    setTimeout(() => {
      this.ngSelectAssinet.focus();
    }, 0);
  }


  /**carga los datos al pinchar en la tabla editar */

  setFormDiverse(params) {
    this.validateDiverse(params.code)
    this.modalHeaderDiverse = 'Modificar Diverso';
    this.id=params.id;
    this.formDiverse.get('code').patchValue(params.code);
    this.formDiverse.get('names').patchValue(params.names);
    this.formDiverse.get('type').patchValue(params.type);
    this.formDiverse.markAsPristine();
    this.open(this.modalDiverse);
    if(this.diverseFinded==true){
      setTimeout(() => {
        this.ngSelectAssinet.focus();
      }, 0);
    }
  }

  validateDiverse(diverse){
    const foundItem = this.dataNgSelectAssinet.find(item => item.diverse === diverse);
    if (foundItem) {
      this.diverseFinded=true
    }
    this.diverseCopy=diverse
  }

  /**al momento de cerrer el ng select pasa el foco al input name y le asigna el valor de name  */
  onCloseNgSelectAssinet() {   
    const selected = this.dataNgSelectAssinet.find(i => i.diverse === this.formDiverse.get('code').value)
    if(selected?.name){
      this.formDiverse.get('names').patchValue(selected.name);
      this.nameInput.nativeElement.focus();
      this.nameInput.nativeElement.select();
    }
  }


  /**abre el modal del diverse */

  openModalChildren() {
    this.modalHeaderChildren = 'Nuevo';
    this.formChildren.get('employee_code').enable()
    this.formChildren.markAsPristine();
    this.formChildren.reset();
    this.open(this.modalChildren);
    setTimeout(() => {
      this.ngSelect.focus();
    }, 0);
  }


  /**carga los datos al pinchar en la tabla editar */

  setFormChildren(params) {
    this.modalHeaderChildren = 'Modificar';
    this.id=params.id;
    this.formChildren.get('employee_code').patchValue(params.diverse);
    this.formChildren.get('employee_code').disable()
    this.formChildren.get('names').patchValue(params.names);
    this.formChildren.get('birthday').patchValue(params.birthday);
    this.formChildren.markAsPristine();
    this.open(this.modalChildren);
    setTimeout(() => {
      this.nameInput.nativeElement.focus();
    }, 0);
  }

  editDiverse(){
    this.diverseFinded=true
  }

  /**cambia la fecha en formato compatible para el mostrar campo de fecha */
  setDate(date?) {
    const expirationDate = date ? new Date(date) : new Date();
    let formatDate = {
      year: date ? expirationDate.getFullYear() : expirationDate.getFullYear() + 1,
      month: expirationDate.getMonth() + 1,
      day: date ? expirationDate.getDate() : expirationDate.getDate() + 1
    };
    return formatDate
  }

  /**cambia la fecha en formato compatible para guardar la fecha en la base de datos */
  setDateSave(date) {
    let year = date.year;
    let month = date.month < 10 ? `0${date.month}` : date.month;
    let day = date.day < 10 ? `0${date.day}` : date.day;
    let newDate = `${year}-${month}-${day}`
    return newDate;
  }

  /**Elimnar diverso */
  deleteDiverse(params) {
    const loadingToast = Swal.fire({
      icon: 'warning',
      title: 'Alerta ',
      text: '¿Estás seguro de eliminar: ' + params.code + ' ' + params.names+'? Se eliminaran todos los registros del usuario incluyendo detalle de salario y de primas infantiles ',
      toast: false,
      position: 'center',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      timer: null,
      timerProgressBar: false
    }).then((result) => {
      if (result.isConfirmed) {
        this._budgeGenrateService.deleteDiverse(params).subscribe(res => {          
          this.dataListDiverse=undefined;
          this.getDiverse();
          this.getDiverseAssinet();
        }, (error => console.error(error)))
      }
    });
  }


  /**Elimnar prima infantil */
  deleteChildren(params) {
    const loadingToast = Swal.fire({
      icon: 'warning',
      title: 'Alerta ',
      text: 'Eliminar: ' + params.diverse+ ' ' + params.names,
      toast: false,
      position: 'center',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      timer: null,
      timerProgressBar: false
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close()
        this._budgeGenrateService.deleteChildren(params).subscribe(res => {
          this.getChildren();
        }, (error => console.error(error)))
      }
    });
  }


  /*envia la informacion para un nuevo diverso*/
  submitDiverseNew() {
    if (this.formDiverse.valid) {
      let data = this.newData(this.formDiverse.value);
      this._budgeGenrateService.saveDiverse(data).subscribe(res => {
        this.close();
        this.dataListDiverse=undefined;        
        this.getDiverse();   
        this.getDiverseAssinet();     
      }, (error => console.error(error)))
    }
  }

  /*envia la informacion para un actualizar diverso*/
  submitDiverseUpdate() {
    if (this.formDiverse.valid) {
      let data = this.newData(this.formDiverse.value);
      data['id']=this.id;
      this._budgeGenrateService.updateDiverse(data).subscribe(res => {        
        this.close();
        this.getDiverse();
        this.getDiverseAssinet();
      }, (error => console.error(error)))

    }
  }


  /*envia la informacion para un nueva prima infantil*/
  submitChildrenNew() {
    if (this.formChildren.valid) {
      let dataSave = this.newData(this.formChildren.value);
      // dataSave['birthday'] = this.setDateSave(dataSave.birthday);
      this._budgeGenrateService.saveChildren(dataSave).subscribe(res => {        
        this.close();
        this.getChildren();
      }, (error => console.error(error)))
    }
  }

  /*envia la informacion para un actualizar prima infantil*/
  submitChildrenUpdate() {
    if (this.formChildren.valid) {
      let dataUpdate = this.newData(this.formChildren.value);
      dataUpdate['id']=this.id;
      // dataUpdate['birthday'] = this.setDateSave(dataUpdate.birthday);
      this._budgeGenrateService.updateChildren(dataUpdate).subscribe(res => {   
        this.close();
        this.getChildren();
      }, (error => console.error(error)))
    }
  }


  /*envia la informacion para actualizar toda prima infantil de la entidad*/
  UpdatePremiumChildren() {
    this._budgeGenrateService.updatePremiumChildren().subscribe(res => {
      this.getChildren();
    }, (error => console.error(error)))
  }
  
  /*Activa o desactiva un diverso*/
  UpdateDiverseActive(params,isActive) {
    this._budgeGenrateService.updateDiverse({id:params.id,is_active:isActive}).subscribe(res => {
      this.getDiverse();
    }, (error => console.error(error)))
  }

  maxDateValidator(control: any) {
    const selectedDate = new Date(control.value);
    const today = new Date();

    // Ajuste para comparar solo fechas (sin horas)
    today.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    if (selectedDate > today) {
      return { dateInvalid: true }; // Error si la fecha es posterior a hoy
    }
    return null; // Válido si es igual o anterior a hoy
  }


}



